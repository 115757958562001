<template>
  <div class="start">
    <el-container>
      <div class="logo1"></div>
      <div class="logo2"></div>
      <div class="logo3"></div>
      <div class="copyRight">版权所有 © 2024 夏岁智能科技</div>
      <el-aside width="50%">
        <div class="title">
          <h1 class="titleShort">研路上岸</h1>
          <h1 class="titleLong">研究生招生信息汇总网</h1>
        </div>
      </el-aside>
      <el-main style="margin-top: 100px;">
        <el-tabs stretch v-model="activeName">
          <el-tab-pane label="登录" name="first">
            <br><br>
            <div class="formBox" v-if="login_type">
              <el-form :model="ruleForm_" status-icon :rules="rules_" ref="ruleForm_" label-width="0">
                <el-form-item prop="email">
                  <el-input placeholder="邮箱" v-model="ruleForm_.email" @keyup.enter.native="handleLogin_pw"></el-input>
                </el-form-item>
                <el-form-item prop="pass">
                  <el-input placeholder="密码" type="password" v-model="ruleForm_.password" autocomplete="off"
                    @keyup.enter.native="handleLogin_pw"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button @click="$router.push('/login/reset_password_request')" class="forget_pass">忘记密码</el-button>
                  <el-button @click="login_type = 0" class="change_button">验证码登录</el-button>
                </el-form-item>
              </el-form>
              <el-button class="log_button" type="primary"
                @click.native.prevent="handleLogin_pw">登&nbsp&nbsp&nbsp录</el-button>
              <div class="privacy_button">
                <el-checkbox v-model="checked">
                  已阅读并同意<span style="color: #653A7D;" @click.stop="agreementBtn">《用户服务协议》</span>和<span
                    style="color: #653A7D;" @click.stop="privacyBtn">《隐私保护协议》</span>
                </el-checkbox>
              </div>
            </div>

            <div class="formBox" v-if="!login_type">
              <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0">
                <el-form-item prop="email">
                  <el-input placeholder="邮箱" v-model="ruleForm.email" @keyup.enter.native="handleLogin"></el-input>
                </el-form-item>
                <el-form-item rop="vercode_user">
                  <el-col :span="15" class="send_input">
                    <el-input placeholder="验证码" v-model="ruleForm.vercode_user" autocomplete="off"
                      @keyup.enter.native="handleLogin"></el-input>
                  </el-col>
                  <el-col :span="3">
                    <!-- 倒计时按钮 -->
                    <el-button type="primary" :disabled="disable" :class="{ codeGetting: isGetting }" @click="sendCode"
                      class="take_mess">
                      {{ getCode }}
                    </el-button>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-button @click="login_type = 1" class="change_button">密码登录</el-button>
                </el-form-item>
              </el-form>
              <el-button class="log_button" type="primary"
                @click.native.prevent="handleLogin">登&nbsp&nbsp&nbsp录</el-button>
              <div class="privacy_button">
                <el-checkbox v-model="checked">
                  已阅读并同意<span style="color: #653A7D;" @click.stop="agreementBtn">《用户服务协议》</span>和<span
                    style="color: #653A7D;" @click.stop="privacyBtn">《隐私保护协议》</span>
                </el-checkbox>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="注册" name="second">
            <br><br>
            <el-form :model="ruleForm_r" status-icon :rules="rules_r" ref="ruleForm_r" label-width="0">
              <el-form-item prop="email" @keyup.enter.native="handleRegister">
                <el-input placeholder="邮箱" v-model="ruleForm_r.email"></el-input>
              </el-form-item>
              <el-form-item lprop="password">
                <el-input placeholder="密码" type="password" v-model="ruleForm_r.password" autocomplete="off"
                  @keyup.enter.native="handleRegister"></el-input>
              </el-form-item>
              <el-form-item prop="rePassword">
                <el-input placeholder="确认密码" type="password" v-model="ruleForm_r.rePassword" autocomplete="off"
                  @keyup.enter.native="handleRegister"></el-input>
              </el-form-item>
              <el-form-item prop="telephone" @keyup.enter.native="handleRegister">
                <el-input placeholder="手机号" v-model="ruleForm_r.telephone"></el-input>
              </el-form-item>
              <el-form-item prop="university " @keyup.enter.native="handleRegister">
                <el-select v-model="ruleForm_r.university" filterable placeholder="学校" style="width: 100%;" clearable
                  remote :remote-method="remoteMethodCollege" :loading="loading">
                  <el-option v-for="(item, index) in collegeList" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="major" @keyup.enter.native="handleRegister">
                <el-select v-model="ruleForm_r.major" filterable placeholder="专业" style="width: 100%;" clearable remote
                  :remote-method="remoteMethod" :loading="loading">
                  <el-option v-for="(item, index) in majorList" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-button class="log_button" type="primary"
                @click.native.prevent="handleRegister">注&nbsp&nbsp&nbsp册</el-button>
              <div class="privacy_button">
                <el-checkbox v-model="checked">
                  已阅读并同意<span style="color: #653A7D;" @click.stop="agreementBtn">《用户服务协议》</span>和<span
                    style="color: #653A7D;" @click.stop="privacyBtn">《隐私保护协议》</span>
                </el-checkbox>
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs> </el-main>
    </el-container>
  </div>
</template>

<script>
import Register from "@/components/Register.vue"
import LoginPassword from "@/components/LoginPassword.vue";
import LoginEmail from "@/components/LoginEmail.vue";
import { getToken } from "@/utils/auth";
import { Message } from "element-ui";
import Cookies from "js-cookie";
import { register, send_email } from "@/network/login";
import { get_college_list, get_major_list } from "@/network/ai";
export default {
  name: "Start",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm_r.rePassword !== '') {
          this.$refs.ruleForm_r.validateField('rePassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm_r.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      collegeList: [],
      collegeStates: [],
      majorList: [],
      majorStates: [],
      activeName: "first",
      login_type: 1, //1 密码登录，0 验证码登录

      //验证码登录
      ruleForm: {
        vercode_user: '',
        email: '',
        vercode: ''
      },
      getCode: '获取验证码',
      isGetting: false,
      count: 60,
      disable: false,
      timer: null,
      rules: {
        vercode_user: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        email: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
      },

      //密码登录
      ruleForm_: {
        password: '',
        email: ''
      },
      rules_: {
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        email: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
      },
      checked: false,

      //注册
      ruleForm_r: {
        password: '',
        rePassword: '',
        email: '',
        telephone: '',
        university: '',
        major: '',
      },
      rules_r: {
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        rePassword: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        email: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "'请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
      }
    };
  },
  components: {// 自定义组件 @组件函数
    "Register": Register,
    "LoginPassword": LoginPassword,
    "LoginEmail": LoginEmail,
  },
  methods: {
    jump_to_csfqiu() {
      //location.href="https://www.xsaifor.com/" // 直接跳转
      window.open("https://www.xsaifor.com/", '_blank')  //打开新页面跳转
    },
    jump_to_department_summary() {
      //location.href="https://www.ucas.ac.cn/site/18"
      window.open("https://www.ucas.ac.cn/site/18", '_blank')
    },
    jump_to_training_unit() {
      // location.href="https://www.ucas.ac.cn/site/3"
      window.open("https://www.ucas.ac.cn/site/3", '_blank')
    },
    handleLogin() { //验证码登录
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          this.$store.dispatch("Login_email", this.ruleForm).then((flag) => {
            if (flag === 1) {
              this.$router.push({ path: "/home" }).catch(() => { });
            }
            else {
              //console.log(flag);
            }
          }).catch((e) => {
            console.log(e);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    agreementBtn() {
      //跳转页面
      this.$router.push({ path: "/agreement" }).catch(() => {
        console.log('222');
      });
    },

    privacyBtn() {
      //跳转页面
      this.$router.push({ path: "/privacy" }).catch(() => {
        console.log('222');
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sendCode() {
      this.$refs.ruleForm.validateField('email', check => {
        if (check === '') {
          this.ruleForm.vercode = Math.random().toFixed(6).slice(-6);
          Cookies.set("vercode", this.ruleForm.vercode, { expires: 60000 / 864e5 });
          Cookies.set("test", 60, { expires: 60000 / 864e5 });
          send_email(this.ruleForm.email, this.ruleForm.vercode).then(res => {
            //增加发送成功与否提示信息
            if (res.flag === 1) {
              this.$message({
                type: 'success',
                message: '发送成功!'
              });
            }
            else if (res.flag === 0) {
              this.$message({
                type: 'warning',
                message: '发送失败'
              });
            }
            console.log(res);
          })
          let _this = this;
          this.timer = setInterval(() => {
            // if (this.count < 1) {
            //   this.isGeting = false
            //   this.disable = false
            //   this.getCode = '获取验证码'
            //   this.count = 6
            //   clearInterval(countDown)
            // } else {
            //   this.isGeting = true
            //   this.disable = true
            //   this.getCode = this.count-- + 's后重发'
            //   console.log(Cookies.get("vercode"));
            // }
            if (Cookies.get("test") < 1) {
              _this.isGetting = false
              _this.disable = false
              _this.getCode = '获取验证码'
              Cookies.set("test", 6)
              clearInterval(this.timer)
            } else {
              _this.isGetting = true
              _this.disable = true
              _this.getCode = Cookies.get("test") + 's后重发'
              Cookies.set("test", Cookies.get("test") - 1)
            }
          }, 1000)
        }
      })
    },

    handleLogin_pw() { //密码登录
      if (!this.checked) {
        this.$message.error('请先阅读并同意《用户服务协议》和《隐私保护协议》');
        return;
      }
      this.$refs.ruleForm_.validate((valid) => {
        if (valid) {
          Cookies.set("email", this.ruleForm_.email, { expires: 30 });
          Cookies.set("password", this.ruleForm_.password, { expires: 30 });
          this.$store.dispatch("Login_password", this.ruleForm_).then((flag) => {
            if (flag === 1) {
              this.$router.push({ path: "/home" }).catch(() => {
              });
            } else {
              console.log(flag);
            }
          }).catch((e) => {
            console.log(e);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    getCollegeList() {
      get_college_list({ email: 'soso@qq.com' }).then(res => {
        console.log(res);
        this.collegeStates = res.results
      })
    },
    remoteMethodCollege(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.collegeList = this.collegeStates.filter(item => {
            return item.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.collegeList = [];
      }
    },
    getMajorList() {
      get_major_list({ email: 'soso@qq.com' }).then(res => {
        console.log(res);
        this.majorStates = res.results
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.majorList = this.majorStates.filter(item => {
            return item.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.majorList = [];
      }
    },

    handleRegister() {
      if (!this.checked) {
        this.$message.error('请先阅读并同意《用户服务协议》和《隐私保护协议》');
        return;
      }
      this.$refs.ruleForm_r.validate((valid) => {
        if (valid) {
          Cookies.set("email", this.ruleForm_r.email, { expires: 30 });
          Cookies.set("password", this.ruleForm_r.password, { expires: 30 });
          register(this.ruleForm_r).then(res => {
            console.log(res);
            if (res.flag === 2) {
              Message({
                message: "注册成功",
                type: 'success',
                duration: 5 * 1000
              })
              this.activeName = 'first'
              this.$router.push({ path: "/" }).catch(() => { });
            }
            else if (res.flag === 0) {
              Message({
                message: "邮箱已被注册",
                type: 'error',
                duration: 5 * 1000
              })
            }
            else {
              Message({
                message: "网络异常",
                type: 'error',
                duration: 5 * 1000
              })
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  mounted() {

  },
  created() {
    this.getCollegeList()
    this.getMajorList()
  },
}
</script>

<style scoped>
.start {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.el-aside {
  /* background: linear-gradient(#d6c5db, #9c7dae); */
  color: white;
  text-align: center;
  display: table;
}

.el-container {
  height: 100vh;
  /* 使用视口高度 */
  position: relative;
  /* overflow: hidden; */
}

.logo1 {
  width: 50vw;
  /* 使用视口宽度百分比 */
  height: 100vh;
  /* 使用视口高度百分比 */
  background: linear-gradient(242deg, #A082B1 8%, #AD92BB 36%, #DDCFE1 100%);
  border-radius: 46px;
  transform: rotate(60deg);
  position: absolute;
  left: -15%;
  /* 使用百分比调整位置 */
  top: -10%;
  /* 使用百分比调整位置 */
  z-index: -1;
}

.logo2 {
  width: 70vw;
  /* 使用视口宽度百分比 */
  height: 70vh;
  /* 使用视口高度百分比 */
  background: #A082B1;
  border-radius: 49px;
  transform: rotate(60deg);
  position: absolute;
  left: 10%;
  /* 使用百分比调整位置 */
  bottom: -80%;
  /* 使用百分比调整位置 */
  z-index: -1;
}

.logo3 {
  width: 50vw;
  /* 使用视口宽度百分比 */
  height: 50vh;
  /* 使用视口高度百分比 */
  border-radius: 50px;
  border: 10px solid #EEE6F0;
  transform: rotate(60deg);
  position: absolute;
  left: -10%;
  /* 使用百分比调整位置 */
  bottom: -35%;
  /* 使用百分比调整位置 */
  z-index: -1;
}

.el-input {
  font-family: inherit;
}

.title {
  font-family: STXinwei, STXinwei;
  /* vertical-align: middle; */
  margin-top: 300px;
  margin-right: 150px;
  /* display: table-cell; */
}

.titleShort {
  font-weight: normal;
  height: 15px;
  font-size: 80px;
}

.titleLong {
  padding-top: 5px;
  height: 10px;
  font-size: 40px;
  font-weight: normal;
}

.el-footer {
  bottom: 0px;
  background-color: #ffffff;
  line-height: 30px;
  height: 30px;
  font-size: 16px;
  color: #333;
}

.el-tabs {
  width: 400px;
  height: 415px;
  background-color: #ffffff;
  margin: 30px auto;
  opacity: 0.6;
  border-radius: 20px;
}

::v-deep .el-tabs__item {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: #18064d;
}

::v-deep .el-tabs__item.is-active {
  font-weight: bold;
  color: #9c7dae;
}

::v-deep .el-tabs__active-bar {
  background-color: #9c7dae;
}

.LoginPassword.active {
  animation: fadeIn 0.8s;
}

.LoginEmail.active {
  animation: fadeIn 0.8s;
}

.formBox {
  width: 100%;
}

.el-button.log_button {
  font-size: 15px;
  border: none;
  text-align: center;
  width: 100%;
  background-color: #d6c5db;
  color: #180b5e;
  border-radius: 3px 3px 3px 3px;
  margin-top: 30px;
}

.el-button.log_button:hover {
  background: #9c7dae;
}

.el-button.change_button {
  background: none;
  border: none;
  position: absolute;
  left: -5%;
}

.el-button.change_button:hover {
  background: none;
}

::v-deep .el-input__inner {
  background: #F8F8F8;
  border-radius: 3px 3px 3px 3px;
  border: none;
  border-left: none;
  border-right: none;
  border-top: none;
  color: #666666;
}

::v-deep .el-input__inner::placeholder {
  color: #666666;
}

.send_input::v-deep .el-input__inner {
  background: #F8F8F8;
  border-radius: 3px 3px 3px 3px;
  border: none;
  border-left: none;
  border-right: none;
  border-top: none;
  color: #666666;
}

.forget_pass {
  background: none;
  border: none;
  position: absolute;
  width: 80px;
  right: 0%;
  color: #a9a9b4;
}

.forget_pass:hover {
  color: #9c7dae;
}

.take_mess {
  background-color: #09073c;
  position: absolute;
  right: 0%;
}

.copyRight {
  color: #9c7dae;
  font-size: 10px;
  position: fixed;
  bottom: 0;
  width: 90%;
  margin: 20px;
  text-align: right;
}

.privacy_button {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
}

:deep(.el-checkbox__input.is-checked+.el-checkbox__label) {
  color: #999999 !important;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background: #653A7D !important;
}

:deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
  border-color: #653A7D !important;
}
</style>
