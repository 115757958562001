<template>
  <div class="container">
    <el-container class="home">
      <el-header class="app_header">
        <el-button id="mainButton" type="text" @click="$router.push('/home')">DAWN</el-button>
        <div class="topBarButtons">
          <el-button type="text" @click="$router.push('/home/headline_news')">头条新闻</el-button>
          <el-button type="text" @click="$router.push('/community/home')">经验交流</el-button>
          <el-button type="text" @click="$router.push('/file/home')">文件共享</el-button>
          <el-button type="text" @click="$router.push('/ai/home')">智能AI</el-button>
          <el-button type="text" @click="$router.push('/course/home')">自我提升</el-button>
          <el-button type="text" @click="$router.push('/intelligence')">智能教研</el-button>
          <el-button type="text" @click="$router.push('/contact_us')">联系我们</el-button>

          <el-badge is-dot v-if="hvNotice" class="app_notification">
            <el-dropdown @command="handleClickNotification">
              <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
              <el-dropdown-menu slot="dropdown" class="app_window">
                <el-dropdown-item command="system">系统通知</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-badge>
          <el-dropdown v-else class="app_notification" @command="handleClickNotification">
            <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
            <el-dropdown-menu slot="dropdown" class="app_window">
              <el-dropdown-item command="system">系统通知</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown class="app_dropdown" @command="handleClickDropdown">
            <span class="el-dropdown-link"><i class="el-icon-user-solid"></i></span>
            <el-dropdown-menu slot="dropdown" class="app_window">
              <el-dropdown-item command="profile">个人中心</el-dropdown-item>
              <el-dropdown-item command="resetPsd">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main class="belowTop">
        <div class="searchRow">
          <el-row type="flex" justify="start">
            <el-col id="btnsCol">
              <el-button class="upperBtns" icon="el-icon-circle-plus"
                @click="$router.push('/file/upload_file')">上传</el-button>
              <el-button class="upperBtns" icon="el-icon-files" @click="handleMyFilesClick">我的上传</el-button>
            </el-col>
            <el-col>
              <div class="search">
                <el-input placeholder="搜索" v-model="keywords" clearable @clear="handleClear"
                  @keyup.enter.native="handleSearch" suffix-icon="el-icon-search"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="boxes">
          <div class="box news">
            <el-table :data="headlineNews" @row-click="newsRowClicked" class="clickable-rows" v-loading="newsLoading">
              <el-table-column prop="file_name" label="头条新闻" fixed="left"></el-table-column>
            </el-table>
          </div>
          <div class="box files">
            <el-tabs type="border-card" v-model="activeName" v-loading="filesLoading">
              <el-tab-pane label="全部" name="all">
                <div style="display: flex;flex-wrap: wrap;">
                  <el-card class="file_card"
                    style="width: calc((100% - 20px) / 3);margin: 0 5px 5px 0;box-shadow:none;border-radius: 3px 3px 3px 3px;border: 1px solid #DEDEDE;"
                    v-for="item in allFiles" :key="item.fileId">
                    <!-- <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                      @click="handleDelete(item)" />
                    <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <el-button class="download downloadBtn" icon="el-icon-download" :loading="downloading"
                      @click="toggleDownload(item)">{{ item.downloadText }} {{ item.downloadNumber }}</el-button>
                    <div v-if="item.isMyPost || item.isMark === 1" class="infoRight postInfo"><i
                        class="el-icon-edit"></i>评分: {{ item.score }}</div>
                    <el-button v-else class="postBtn markBtn" :loading="rateLoad" icon="el-icon-edit"
                      @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                    <score-popup ref="scorePopup" />
                    <div v-if="item.isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{
                      item.collectNumber }}</div>
                    <el-button v-else class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{
                      item.collectText }} {{ item.collectNumber }}</el-button> -->

                    <div
                      style="display: flex; align-items: center; justify-content: space-between; color: #7A557B;padding: 20px;">
                      {{ item.nickname }}
                      <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                        @click="handleDelete(item)" />
                      <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                        {{ item.followedText }}
                      </el-button>
                    </div>
                    <div style="display: flex;flex-direction: column;padding: 0 20px;">
                      <div class="cardDiv postTitle">{{ item.file_name }}</div>
                      <div class="cardDiv postContent">{{ item.content }}</div>
                    </div>
                    <div
                      style="margin-top: 50px;display: flex; align-items: center; justify-content: space-between; padding: 0 20px;">
                      <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                      <el-button class="download_btn" :loading="downloading" @click="toggleDownload(item)">{{
                        item.downloadText }} {{ item.downloadNumber }}</el-button>
                    </div>
                    <div
                      style="display: flex;align-items: center; justify-content: flex-end;color: #E3E2E3;background: #FBFAFB;border-top: 1px solid #DEDEDE;border-radius: 3px 3px 3px 3px;">
                      <div style="margin: 30px 10px 15px 0; display: flex;">
                        <div v-if="item.isMyPost" class="option option_margin"><i
                            class="el-icon-chat-line-star-off"></i>收藏
                          {{ item.collectNumber }}
                        </div>
                        <el-button v-else class="option_btn option_margin" icon="el-icon-star-off"
                          @click="toggleCollect(item)">{{
                            item.collectText }} {{ item.collectNumber }}</el-button>
                        <div v-if="item.isMyPost || item.isMark === 1" class="option"><i class="el-icon-edit"></i>评分: {{
                          item.score }}</div>
                        <el-button v-else class="option_btn" :loading="rateLoad" icon="el-icon-edit"
                          @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                        <score-popup ref="scorePopup" />
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="热门" name="hot">
                <div style="display: flex;flex-wrap: wrap;">
                  <el-card class="file_card"
                    style="width: calc((100% - 20px) / 3);margin: 0 5px 5px 0;box-shadow:none;border-radius: 3px 3px 3px 3px;border: 1px solid #DEDEDE;"
                    v-for="item in hotFiles" :key="item.fileId">
                    <!-- <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                      @click="handleDelete(item)" />
                    <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <el-button class="download downloadBtn" icon="el-icon-download" :loading="downloading"
                      @click="toggleDownload(item)">{{ item.downloadText }} {{ item.downloadNumber }}</el-button>
                    <div v-if="item.isMyPost || item.isMark === 1" class="infoRight postInfo"><i
                        class="el-icon-edit"></i>评分: {{ item.score }}</div>
                    <el-button v-else class="postBtn markBtn" icon="el-icon-edit" :loading="rateLoad"
                      @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                    <score-popup ref="scorePopup" />
                    <div v-if="item.isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{
                      item.collectNumber }}</div>
                    <el-button v-else class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{
                      item.collectText }} {{ item.collectNumber }}</el-button> -->

                    <div
                      style="display: flex; align-items: center; justify-content: space-between; color: #7A557B;padding: 20px;">
                      {{ item.nickname }}
                      <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                        @click="handleDelete(item)" />
                      <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                        {{ item.followedText }}
                      </el-button>
                    </div>
                    <div style="display: flex;flex-direction: column;padding: 0 20px;">
                      <div class="cardDiv postTitle">{{ item.file_name }}</div>
                      <div class="cardDiv postContent">{{ item.content }}</div>
                    </div>
                    <div
                      style="margin-top: 50px;display: flex; align-items: center; justify-content: space-between; padding: 0 20px;">
                      <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                      <el-button class="download_btn" :loading="downloading" @click="toggleDownload(item)">{{
                        item.downloadText }} {{ item.downloadNumber }}</el-button>
                    </div>
                    <div
                      style="display: flex;align-items: center; justify-content: flex-end;color: #E3E2E3;background: #FBFAFB;border-top: 1px solid #DEDEDE;border-radius: 3px 3px 3px 3px;">
                      <div style="margin: 30px 10px 15px 0; display: flex;">
                        <div v-if="item.isMyPost" class="option option_margin"><i
                            class="el-icon-chat-line-star-off"></i>收藏
                          {{ item.collectNumber }}
                        </div>
                        <el-button v-else class="option_btn option_margin" icon="el-icon-star-off"
                          @click="toggleCollect(item)">{{
                            item.collectText }} {{ item.collectNumber }}</el-button>
                        <div v-if="item.isMyPost || item.isMark === 1" class="option"><i class="el-icon-edit"></i>评分: {{
                          item.score }}</div>
                        <el-button v-else class="option_btn" :loading="rateLoad" icon="el-icon-edit"
                          @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                        <score-popup ref="scorePopup" />
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="关注" name="following">
                <div style="display: flex;flex-wrap: wrap;">
                  <el-card class="file_card"
                    style="width: calc((100% - 20px) / 3);margin: 0 5px 5px 0;box-shadow:none;border-radius: 3px 3px 3px 3px;border: 1px solid #DEDEDE;"
                    v-for="item in followedFiles" :key="item.fileId">
                    <!-- <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                      @click="handleDelete(item)" />
                    <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <el-button class="download downloadBtn" icon="el-icon-download" :loading="downloading"
                      @click="toggleDownload(item)">{{ item.downloadText }} {{ item.downloadNumber }}</el-button>
                    <div v-if="item.isMark === 1" class="infoRight postInfo"><i class="el-icon-edit"></i>评分: {{
                      item.score }}</div>
                    <el-button class="postBtn markBtn" icon="el-icon-edit" :loading="rateLoad"
                      @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                    <score-popup ref="scorePopup" />
                    <el-button class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{
                      item.collectText }} {{ item.collectNumber }}</el-button> -->
                    <div
                      style="display: flex; align-items: center; justify-content: space-between; color: #7A557B;padding: 20px;">
                      {{ item.nickname }}
                      <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                        @click="handleDelete(item)" />
                      <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                        {{ item.followedText }}
                      </el-button>
                    </div>
                    <div style="display: flex;flex-direction: column;padding: 0 20px;">
                      <div class="cardDiv postTitle">{{ item.file_name }}</div>
                      <div class="cardDiv postContent">{{ item.content }}</div>
                    </div>
                    <div
                      style="margin-top: 50px;display: flex; align-items: center; justify-content: space-between; padding: 0 20px;">
                      <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                      <el-button class="download_btn" :loading="downloading" @click="toggleDownload(item)">{{
                        item.downloadText }} {{ item.downloadNumber }}</el-button>
                    </div>
                    <div
                      style="display: flex;align-items: center; justify-content: flex-end;color: #E3E2E3;background: #FBFAFB;border-top: 1px solid #DEDEDE;border-radius: 3px 3px 3px 3px;">
                      <div style="margin: 30px 10px 15px 0; display: flex;">
                        <div v-if="item.isMyPost" class="option option_margin"><i
                            class="el-icon-chat-line-star-off"></i>收藏
                          {{ item.collectNumber }}
                        </div>
                        <el-button v-else class="option_btn option_margin" icon="el-icon-star-off"
                          @click="toggleCollect(item)">{{
                            item.collectText }} {{ item.collectNumber }}</el-button>
                        <div v-if="item.isMyPost || item.isMark === 1" class="option"><i class="el-icon-edit"></i>评分: {{
                          item.score }}</div>
                        <el-button v-else class="option_btn" :loading="rateLoad" icon="el-icon-edit"
                          @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                        <score-popup ref="scorePopup" />
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="收藏" name="collected">
                <div style="display: flex;flex-wrap: wrap;">
                  <el-card class="file_card"
                    style="width: calc((100% - 20px) / 3);margin: 0 5px 5px 0;box-shadow:none;border-radius: 3px 3px 3px 3px;border: 1px solid #DEDEDE;"
                    v-for="item in collectedFiles" :key="item.fileId">
                    <!-- <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                      @click="handleDelete(item)" />
                    <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <el-button class="download downloadBtn" icon="el-icon-download" :loading="downloading"
                      @click="toggleDownload(item)">{{ item.downloadText }} {{ item.downloadNumber }}</el-button>
                    <div v-if="item.isMark === 1" class="infoRight postInfo"><i class="el-icon-edit"></i>评分: {{
                      item.score }}</div>
                    <el-button class="postBtn markBtn" icon="el-icon-edit" :loading="rateLoad"
                      @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                    <score-popup ref="scorePopup" />
                    <el-button class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{
                      item.collectText }} {{ item.collectNumber }}</el-button> -->
                    <div
                      style="display: flex; align-items: center; justify-content: space-between; color: #7A557B;padding: 20px;">
                      {{ item.nickname }}
                      <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                        @click="handleDelete(item)" />
                      <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                        {{ item.followedText }}
                      </el-button>
                    </div>
                    <div style="display: flex;flex-direction: column;padding: 0 20px;">
                      <div class="cardDiv postTitle">{{ item.file_name }}</div>
                      <div class="cardDiv postContent">{{ item.content }}</div>
                    </div>
                    <div
                      style="margin-top: 50px;display: flex; align-items: center; justify-content: space-between; padding: 0 20px;">
                      <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                      <el-button class="download_btn" :loading="downloading" @click="toggleDownload(item)">{{
                        item.downloadText }} {{ item.downloadNumber }}</el-button>
                    </div>
                    <div
                      style="display: flex;align-items: center; justify-content: flex-end;color: #E3E2E3;background: #FBFAFB;border-top: 1px solid #DEDEDE;border-radius: 3px 3px 3px 3px;">
                      <div style="margin: 30px 10px 15px 0; display: flex;">
                        <div v-if="item.isMyPost" class="option option_margin"><i
                            class="el-icon-chat-line-star-off"></i>收藏
                          {{ item.collectNumber }}
                        </div>
                        <el-button v-else class="option_btn option_margin" icon="el-icon-star-off"
                          @click="toggleCollect(item)">{{
                            item.collectText }} {{ item.collectNumber }}</el-button>
                        <div v-if="item.isMyPost || item.isMark === 1" class="option"><i class="el-icon-edit"></i>评分: {{
                          item.score }}</div>
                        <el-button v-else class="option_btn" :loading="rateLoad" icon="el-icon-edit"
                          @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                        <score-popup ref="scorePopup" />
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="已下载" name="downloaded">
                <div style="display: flex;flex-wrap: wrap;">
                  <el-card class="file_card"
                    style="width: calc((100% - 20px) / 3);margin: 0 5px 5px 0;box-shadow:none;border-radius: 3px 3px 3px 3px;border: 1px solid #DEDEDE;"
                    v-for="item in downloadedFiles" :key="item.fileId">
                    <!-- <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                      @click="handleDelete(item)" />
                    <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                      {{ item.followedText }}
                    </el-button>
                    <div class="cardDiv nickname">{{ item.nickname }}</div>
                    <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                    <div class="cardDiv postTitle">{{ item.file_name }}</div>
                    <div class="cardDiv postContent">{{ item.content }}</div>
                    <el-button class="download downloadBtn" icon="el-icon-download" :loading="downloading"
                      @click="toggleDownload(item)">{{ item.downloadText }} {{ item.downloadNumber }}</el-button>
                    <div v-if="item.isMyPost || item.isMark === 1" class="infoRight postInfo"><i
                        class="el-icon-edit"></i>评分: {{ item.score }}</div>
                    <el-button v-else class="postBtn markBtn" :loading="rateLoad" icon="el-icon-edit"
                      @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                    <score-popup ref="scorePopup" />
                    <div v-if="item.isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{
                      item.collectNumber }}</div>
                    <el-button v-else class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect(item)">{{
                      item.collectText }} {{ item.collectNumber }}</el-button> -->

                    <div
                      style="display: flex; align-items: center; justify-content: space-between; color: #7A557B;padding: 20px;">
                      {{ item.nickname }}
                      <el-button v-if="item.isMyPost" class="postBtn deleteBtn" icon="el-icon-delete"
                        @click="handleDelete(item)" />
                      <el-button v-else class="postBtn followBtn" @click="toggleFollow(item)">
                        {{ item.followedText }}
                      </el-button>
                    </div>
                    <div style="display: flex;flex-direction: column;padding: 0 20px;">
                      <div class="cardDiv postTitle">{{ item.file_name }}</div>
                      <div class="cardDiv postContent">{{ item.content }}</div>
                    </div>
                    <div
                      style="margin-top: 50px;display: flex; align-items: center; justify-content: space-between; padding: 0 20px;">
                      <div class="cardDiv postTime">发布于 {{ item.create_date }}</div>
                      <el-button class="download_btn" :loading="downloading" @click="toggleDownload(item)">{{
                        item.downloadText }} {{ item.downloadNumber }}</el-button>
                    </div>
                    <div
                      style="display: flex;align-items: center; justify-content: flex-end;color: #E3E2E3;background: #FBFAFB;border-top: 1px solid #DEDEDE;border-radius: 3px 3px 3px 3px;">
                      <div style="margin: 30px 10px 15px 0; display: flex;">
                        <div v-if="item.isMyPost" class="option option_margin"><i
                            class="el-icon-chat-line-star-off"></i>收藏
                          {{ item.collectNumber }}
                        </div>
                        <el-button v-else class="option_btn option_margin" icon="el-icon-star-off"
                          @click="toggleCollect(item)">{{
                            item.collectText }} {{ item.collectNumber }}</el-button>
                        <div v-if="item.isMyPost || item.isMark === 1" class="option"><i class="el-icon-edit"></i>评分: {{
                          item.score }}</div>
                        <el-button v-else class="option_btn" :loading="rateLoad" icon="el-icon-edit"
                          @click="toggleRating(item)">评分: {{ item.score }}</el-button>
                        <score-popup ref="scorePopup" />
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="box ads" v-loading="adsLoading">
            <!-- <div>
              <img v-for="(image, index) in ads" :key="index" :src="image" alt="Base64 Image" class="adsImg">
            </div> -->
            <div style="background: #FFFFFF;border-bottom: 1px solid #DEDEDE;padding: 12px 10px;text-align: left;">头条新闻
            </div>
            <div class="box_news" v-for="(item, index) in newsData" :key="index">
              <div style="color: #7A557B;margin-bottom: 10px;">{{ item.file_name }}</div>
              <div style="display: flex; align-items: center;justify-content: space-between;">
                <span style="font-size: 14px;color: #666666;">{{ item.file_content }}</span>
                <span style="color: #999999;font-size: 12px;">发布于 {{ item.file_time }}</span>
              </div>
            </div>
          </div>
          <el-dialog title="评分" :visible.sync="popupVisible" :before-close="handleClose" :append-to-body="true">
            <div class="popup-content">
              <div class="stars">
                <h3>选择分数后提交即可评分</h3>
                <div v-for="mark in 10" :key="mark" @click="rate(mark)" :class="{ filled: mark <= selectedMark }"
                  class="markDiv">{{ mark }}</div>
              </div>
              <el-button class="closeBtn popupBtn" @click="closePopup">关闭</el-button>
              <el-button class="submitBtn popupBtn" @click="submitRating">提交</el-button>
            </div>
          </el-dialog>
        </div>
      </el-main>

    </el-container>
  </div>
</template>
<script>
import { get_headline_news } from "@/network/home";
import {
  get_files,
  get_followed_files,
  get_popular_files,
  get_all_collected_files,
  get_all_downloaded_files,
  collect_file,
  uncollect_file,
  follow_user,
  unfollow_user,
  delete_file,
  get_ads,
  download_file,
  mark_file
} from "@/network/file";
import { get_notifications } from "@/network/notification";
import ScorePopup from './ScorePopup.vue';

export default {
  name: "CommunityHome",
  data() {
    return {
      email: '',
      keywords: '',
      activeName: 'all',
      hotFiles: [],
      suggestedFiles: [],
      followedFiles: [],
      allFiles: [],
      downloadedFiles: [],
      collectedFiles: [],
      hotFilesAll: [],
      suggestedFilesAll: [],
      followedFilesAll: [],
      allFilesAll: [],
      downloadedFilesAll: [],
      collectedFilesAll: [],
      newsLoading: true,
      filesLoading: true,
      adsLoading: true,
      downloading: false,
      ads: [],
      rateLoad: false,
      hvNotice: false,
      headlineNews: [
        {
          "newsType": '',
          "fid": 0,
          "infoType": 0,
          "dpName": 0,
          "file_name": "加载中",
          "link": "https://www.xsaifor.com/"
        }
      ],
      popupVisible: false,
      selectedMark: 0,
      fileId: -1,
      updateInsertOpen: false,
      newsData: [
        {
          file_name: '啦啦',
          file_content: '测试pdf',
          file_time: '2024/09/10 15:25'
        },
        {
          file_name: '啦啦',
          file_content: '测试pdf',
          file_time: '2024/09/10 15:25'
        },
      ],
    };
  },
  components: {
    ScorePopup,
  },
  methods: {
    handleClear() {
      this.resetDisplayFiles();
      // this.headlineNews = this.headlineNewsAll;
      // this.hadleGetFilesListApi();//刷新页面
    },
    handleSearch() {
      this.hotFiles = this.hotFilesAll.filter((p) => {
        return p.file_name.indexOf(this.keywords) !== -1;
      })
      this.suggestedFiles = this.suggestedFilesAll.filter((p) => {
        return p.file_name.indexOf(this.keywords) !== -1;
      })
      this.followedFiles = this.followedFilesAll.filter((p) => {
        return p.file_name.indexOf(this.keywords) !== -1;
      })
      this.allFiles = this.allFilesAll.filter((p) => {
        return p.file_name.indexOf(this.keywords) !== -1;
      })
      this.downloadedFiles = this.downloadedFilesAll.filter((p) => {
        return p.file_name.indexOf(this.keywords) !== -1;
      })
      this.collectedFiles = this.collectedFilesAll.filter((p) => {
        return p.file_name.indexOf(this.keywords) !== -1;
      })
    },
    resetDisplayFiles() {
      this.hotFiles = this.hotFilesAll;
      this.suggestedFiles = this.suggestedFilesAll;
      this.followedFiles = this.followedFilesAll;
      this.allFiles = this.allFilesAll;
      this.downloadedFiles = this.downloadedFilesAll;
      this.collectedFiles = this.collectedFilesAll;
    },
    newsRowClicked(row) {  //跳转到原文
      //location.href=row.link // 直接跳转
      window.open(row.link, '_blank')  //打开新页面跳转
    },
    handleClickNotification(command) {
      if (command === "system") {
        this.$router.push('/notification/');
      }
    },
    handleClickDropdown(command) {
      if (command === "logout") {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push('/login/');
        })
      }
      else if (command === "profile") {
        this.$router.push('/profile/');
      }
      else if (command === "resetPsd") {
        this.$router.push('/reset_password/');
      }
    },
    handleDelete(item) {
      event.stopPropagation();
      this.$confirm('是否要删除文件', '系统提示', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'info'
      }).then(() => {
        delete_file(this.email, item.fileId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已删除文件'
            });
            this.fetchFiles();
          }
          else {
            this.$message({
              type: 'warning',
              message: '删除文件失败，请稍后重试'
            });
          }
        })
      }).catch(() => { });
      document.activeElement.blur();
    },
    tabChange() {
      if (this.keywords !== '') {
        this.fetchFiles();
        this.keywords = '';
      }
    },
    toggleFollow(item) {
      event.stopPropagation();
      item.followedText = item.followedText === '已关注' ? '+关注' : '已关注';
      if (item.isFollow === 0) {
        item.isFollow = 1;
        follow_user(this.email, item.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已关注'
            });
            this.fetchFiles();
          }
          else {
            item.isFollow = 0;
            item.followedText = item.followedText === '已关注' ? '+关注' : '已关注';
            this.$message({
              type: 'warning',
              message: '关注失败，请稍后重试'
            });
          }
        })
      }
      else {
        item.isFollow = 0;
        unfollow_user(this.$store.getters.email, item.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已取消关注'
            });
            this.fetchFiles();
          }
          else {
            item.isFollow = 1;
            item.followedText = item.followedText === '已关注' ? '+关注' : '已关注';
            this.$message({
              type: 'warning',
              message: '取消关注失败，请稍后重试'
            });
          }
        })
      }
      document.activeElement.blur();
    },
    toggleDownload(item) {
      this.downloading = true;
      event.stopPropagation();
      download_file(this.email, item.fileId);
      // const url = "https://postgraduate.xsaifor.com/prod-api/files/download_file?email="+this.email+'&fid='+item.fileId;
      // window.open(url);
      // this.$nextTick(() => {
      // });
      setTimeout(() => {
        this.fetchFiles();
        //item.downloadNumber += item.downloadText === '已下载'? 0 : 1;
        //item.downloadText = item.downloadText === '已下载'? '下载' : '已下载';
        this.downloading = false;
      }, 8000)
      document.activeElement.blur();
    },
    toggleRating(item) {
      this.rateLoad = true;
      event.stopPropagation();
      if (item.isDownloaded) {
        this.openPopup(item.fileId);
      }
      else {
        this.$message({
          type: 'warning',
          message: '请先下载再进行评分'
        });
        this.rateLoad = false;
      }
      document.activeElement.blur();
    },
    toggleCollect(item) {
      event.stopPropagation();
      item.collectText = item.collectText === '已收藏' ? '收藏' : '已收藏';
      if (item.isCollect === 0) {
        item.isCollect = 1;
        item.collectNumber++;
        collect_file(this.email, item.fileId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已收藏'
            });
            this.fetchFiles();
          }
          else {
            item.isCollect = 0;
            item.collectNumber--;
            item.collectText = item.collectText === '已收藏' ? '收藏' : '已收藏';
            this.$message({
              type: 'warning',
              message: '收藏失败，请稍后重试'
            });
          }
        })
      }
      else {
        item.isCollect = 0;
        item.collectNumber--;
        uncollect_file(this.email, item.fileId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已取消收藏'
            });
            this.fetchFiles();
          }
          else {
            item.isCollect = 1;
            item.collectNumber++;
            item.collectText = item.collectText === '已收藏' ? '收藏' : '已收藏';
            this.$message({
              type: 'warning',
              message: '取消收藏失败，请稍后重试'
            });
          }
        })
      }
      document.activeElement.blur();
    },
    handlePostClick(item) {
      this.$router.push({
        name: 'post', params: {
          post: item
        }
      });
    },
    handleMyFilesClick() {
      this.$router.push({
        name: 'files_view', params: {
          uid: this.email
        }
      });
    },
    fetchFiles() {
      get_popular_files(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.hotFiles = res.data;
          this.hotFiles.forEach(post => { this.initFilePost(post) });
          this.hotFilesAll = this.hotFiles;
        }
        else {
          this.hotFiles = [];
        }
      }).catch((error) => {
        console.log(error);
      });
      get_followed_files(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.followedFiles = res.data
          this.followedFiles.forEach(post => { this.initFilePost(post) });
          this.followedFilesAll = this.followedFiles;
        }
        else {
          this.followedFiles = [];
        }
      }).catch((error) => {
        console.log(error);
      });
      get_files(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.allFiles = res.data
          this.allFiles.forEach(post => { this.initFilePost(post) });
          this.allFilesAll = this.allFiles;
        }
        else {
          this.allFiles = [];
        }
        this.filesLoading = false;
      }).catch((error) => {
        console.log(error);
      });
      get_all_collected_files(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.collectedFiles = res.data
          this.collectedFiles.forEach(post => { this.initFilePost(post) });
          this.collectedFilesAll = this.collectedFiles;
        }
        else {
          this.collectedFiles = [];
        }
      }).catch((error) => {
        console.log(error);
      });
      get_all_downloaded_files(this.email).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.downloadedFiles = res.data
          this.downloadedFiles.forEach(post => { this.initFilePost(post) });
          this.downloadedFilesAll = this.downloadedFiles;
        }
        else {
          this.downloadedFiles = [];
        }
      }).catch((error) => {
        console.log(error);
      });
      this.$forceUpdate();
    },
    initFilePost(post) {
      post['followedText'] = post.isFollow === 0 ? '+关注' : '已关注';
      post['collectText'] = post.isCollect === 0 ? '收藏' : '已收藏';
      post['downloadText'] = post.isDownloaded === 0 ? '下载' : '已下载';
      post['isMyPost'] = (post.ownerId === this.email);
    },
    openPopup(fileId) {
      this.popupVisible = true;
      this.$nextTick(() => {
        this.selectedMark = 0; // 重置评分
        this.fileId = fileId;
      })
    },
    handleClose(done) {
      this.popupVisible = false;
      this.rateLoad = false;
      done();
    },
    closePopup() {
      this.popupVisible = false;
      this.rateLoad = false;
    },
    submitRating() {
      let email = this.$store.getters.email;
      event.stopPropagation();
      this.$confirm('是否确认提交评分', '系统提示', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'info'
      }).then(() => {
        mark_file(email, this.fileId, this.selectedMark).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已提交评分'
            });
          }
          else {
            this.$message({
              type: 'warning',
              message: '提交评分失败，请稍后重试'
            });
          }
        })
        this.fetchFiles();
        this.popupVisible = false;
      }).catch(() => { });
      document.activeElement.blur();
    },
    rate(mark) {
      this.selectedMark = mark;
    },
  },
  created() {
    this.email = this.$store.getters.email;
    get_headline_news(this.email).then((res) => {
      if (res !== undefined && res.files !== undefined) {
        this.headlineNews = res.files;//后端数据查询时以控制有序
      }
      else {
        this.headlineNews = [];
      }
      this.newsLoading = false;
    }).catch((error) => {
      console.log(error);
    });
    get_ads(this.email).then((res) => {
      if (res !== undefined && res.data !== undefined) {
        var str = new TextDecoder().decode(res.data)
        var json = JSON.parse(str)
        json.forEach(ad => {
          let str = ad.replace(/\. +/g, '')
          str = str.replace(/[\r\n]/g, '')
          this.ads.push(str)
        });
      }
      else {
        this.ads = [];
      }
      this.adsLoading = false;
    }).catch((error) => {
      console.log(error);
    });
    get_notifications(this.email).then((res) => {
      let notices = res.data;
      notices.forEach(notice => {
        if (notice.isRead === 0) {
          this.hvNotice = true;
        }
      });
    })
    // console.log(this.ads);
    this.fetchFiles();
  },
}
</script>

<style scoped>
/* .home {
  overflow: hidden;
} */
.el-container {
  margin: 0px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.search ::v-deep .el-input__inner {
  height: 40px;
  border-radius: 40px;
  width: 600px;
  float: right;
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
}

.search ::v-deep .el-input__inner:focus {
  border-color: #7a557b;
}

.searchRow {
  height: 40px;
  /* margin-top: 60px; */
}

.el-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #7a557b;
  z-index: 1000;
  overflow: hidden;
}

.app_header .el-button {
  color: white;
  margin: 10px;
}

.el-main {
  /* background-color: #f3e6f6; */
  color: #333;
  text-align: center;
  flex: 1;
  overflow: hidden;
}

.belowTop {
  margin-top: 60px;
  height: calc(100vh - 60px);
  width: 100%;
  flex: 1;
}

#mainButton {
  position: absolute;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  left: 0;
  font-size: 30px;
  margin-left: 30px;
  top: 0;
  margin-top: 0;
}

.app_dropdown {
  position: absolute;
  right: 0;
  top: 20px;
  margin-right: 30px;
}

.el-dropdown-menu__item:hover {
  background-color: #f3e6f6;
  color: #7a517c;
}

.el-icon-user-solid {
  color: white;
  font-size: 20px;
}

.app_notification {
  position: absolute;
  right: 50px;
  top: 20px;
  margin-right: 30px;
}

::v-deep .el-badge__content.is-dot {
  border: none;
}

.el-icon-bell {
  color: white;
  font-size: 20px;
}

#btnsCol {
  text-align: start;
}

.upperBtns {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  border: 1px#7a557b;
  height: 40px;
  color: #7a557b;
  border: 1px solid #7a557b;
  /* margin-top: 60px; */
}

.upperBtns:hover {
  color: #f3e6f6;
  background-color: #7a557b;
}

.boxes {
  margin-top: 40px;
  display: flex;
  flex: 1;
  gap: 10px;
}

.box {
  /* flex: 1; */
  border: 1px solid #ccc;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  border-radius: 10px;
}

.news,
.ads {
  width: 25%;
}


.adsImg {
  max-width: 90%;
  margin: 10px;
}

.files {
  width: 50%;
}

::v-deep .el-tabs__item.is-active {
  color: #7a557b !important;
  background-color: #f3e6f6;
}

::v-deep .el-tabs__item:hover {
  color: #7a557b !important;
}

/* Files CSS */
.cardDiv {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.el-card {
  text-align: start;
}

.postBtn {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  padding: 5px;
  float: right;
  font-size: small;
  border: 1px#7a557b;
  height: 25px;
  width: 50px;
  margin: 10px 0;
  color: #7a557b;
}

.followBtn {
  border: 1px dashed #7a557b;
}

.followBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}

.markBtn {
  width: 80px;
  border: 1px #7a557b;
}

.markBtn:focus {
  background-color: white !important;
  border: 0;
}

.markBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}

.collectBtn {
  width: 80px;
  margin-right: 5px;
  border: 1px #7a557b;
}

.collectBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}

.postTitle {
  font-size: 16px;
  margin-top: 10px;
}

.nickname {
  font-size: 12px;
}

.postTime {
  margin: 5px 0;
  font-size: 11px;
}

.download {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  padding: 5px;
  float: left;
  font-size: small;
  border: 1px#7a557b;
  height: 25px;
  width: 50px;
  margin: 10px 0;
  color: #7a557b;
}

.downloadBtn {
  width: 80px;
  margin-right: 5px;
  border: 1px #7a557b;
}

.downloadBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}

.el-icon-chat-line-square {
  padding-right: 5px;
}

.postContent {
  margin-top: 20px;
  font-size: 13px;
}

.postInfo {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-size: small;
  height: 25px;
  margin: 10px 0;
  color: #7a557b;
  align-content: center;
  color: #333;
}

.infoRight {
  float: right;
  margin-left: 10px;
}

.score-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f3e6f6;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.markDiv {
  border: solid 1px #ccc;
  display: inline-block;
  border-radius: 3px;
  width: 30px;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
  color: #ccc;
  font-size: 24px;
}

.markDiv.filled {
  background-color: #7a557b;
  border-color: #7a557b;
}

.markDiv:hover {
  background-color: #f3e6f6;
  color: #7a557b
}

.closeBtn {
  background-color: white;
  color: #7a557b;
  border: #7a557b solid 1px;
}

.submitBtn {
  background-color: #7a557b;
  color: #f3e6f6;
  border: #7a557b solid 1px;
}

.popupBtn {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  margin-top: 20px;
}

.popupBtn:hover {
  background-color: #f3e6f6;
  border-color: #f3e6f6;
  color: #7a557b;
}

.popup-content {
  text-align: center;
}

.file_card :deep(.el-card__body) {
  padding: 0;
}

.download_btn {
  color: #333333;
  border: none;
  background: none;
  font-size: 12px;
  padding: 0;
}

.option_btn {
  color: #3D3D3D;
  border: none;
  background: none;
  padding: 0;
  font-size: 12px;
  line-height: 0;
  margin: 0;
}

.option {
  color: #333333;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 0;
}

.option_margin {
  margin-right: 20px;
}

.box_news {
  margin: 10px;
  background: #FFFFFF;
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #DEDEDE;
  padding: 10px 15px;
  text-align: left;
}
</style>