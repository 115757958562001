<template>
  <div class="container">
    <el-container class="home">
      <el-header class="app_header">
        <el-button class="mainButton homeBtn" type="text" @click="$router.push('/home')">DAWN</el-button>
        <el-button class="mainButton backBtn" icon="el-icon-back" @click="goBack">返回&nbsp |
          &nbsp&nbsp&nbsp会员中心</el-button>
      </el-header>
      <el-main class="belowTop" style="padding: 0;">
        <div class="notification">
          <el-carousel height="50px" arrow="never" indicator-position="none" direction="vertical">
            <el-carousel-item v-for="item in notificationList" :key="item" style="display: flex;align-items: center;">
              <img src="@/assets/img/notification377@2x.png" alt="" width="20px" style="padding-left: 50px;">
              <div class="small">{{ item }}</div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="topDiv" style="padding: 20px;">
          <div class="getVIPDiv">
            <div class="getVIPDiv_shadow">
              <img src="@/assets/img/容器@2x.png" alt="">
            </div>
            <div class="vipTextDiv">
              {{ vipText }}
              <div class="vipTextDiv_shadow">{{ vipText }}</div>
            </div>
            <div class="break"></div> <!-- break -->
            <div class="vipBtnDiv"><el-button class="vipBtn" @click="dialogVisible = true">{{ getVipText
                }}DAWN会员</el-button></div>
          </div>
          <div class="vipInfoDiv">
            <!-- <div class="infoInner"> -->
            <div class="rowDiv">
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                <div class="rowDiv_nickname">{{ nickname }}</div>
                <div class="rowDiv_member">{{ membership }}</div>
              </div>

              <div class="rowCenterDiv">
                <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                  <div class="rowDiv_levelText">积分</div>
                  <div class="rowDiv_levelText">{{ points }}</div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                  <div class="rowDiv_levelText">等级</div>
                  <div class="rowDiv_levelText">{{ level }}</div>
                </div>
              </div>
            </div>
            <div class="rowDiv">
              <div class="rowDiv_Text">剩余下载次数：{{ downloadTimes }}</div>
              <div class="rowDiv_Text">到期时间：{{ vipExpiration }}</div>
            </div>


            <!-- <div class="rowDiv">
                <div class="rowCenterDiv">
                  <div class="info dynamicVal name">{{ nickname }}</div>
                  <div class="info staticText member">{{ membership }}</div>
                </div>
              </div>
              <div class="rowDiv">
                <div class="rowCenterDiv">
                  <div class="info staticText row23 downTimeText">剩余下载次数</div>
                  <div class="info staticText row23 pointsText">积分</div>
                  <div class="info staticText row23 levelText">等级</div>
                </div>
              </div>
              <div class="rowDiv">
                <div class="rowCenterDiv">
                  <div class="info row23 dynamicVal downTime">{{ downloadTimes }}</div>
                  <div class="info row23 dynamicVal points">{{ points }}</div>
                  <div class="info row23 dynamicVal level">{{ level }}</div>
                </div>
              </div>
              <div class="rowDiv">
                <div class="rowCenterDiv">
                  <div class="info staticText expiration expirationText">到期时间</div>
                  <div class="info dynamicVal expiration expirationDate">{{ vipExpiration }}</div>
                </div>
              </div> -->
            <!-- </div> -->
          </div>
        </div>
        <div class="vipBenefitsText">
          <img src="@/assets/img/member@2x.png" class="member_img" alt="">
          <div style="display: flex; align-items: center; margin-left: 20px;">VIP权益</div>
        </div>
        <div class="bottomDiv">
          <div class="benefitDiv">
            <div class="benefitDiv_img">
              <img src="@/assets/img/Report.png" class="code_img" alt="">
            </div>
            <div class="benefitHeader">进阶版定位报告</div>
            <div class="benefitInfo">您将获得更高质量，更加详细的个人定位报告和更专业的个人规划报告</div>
          </div>
          <div class="benefitDiv">
            <div class="benefitDiv_img">
              <img src="@/assets/img/ai@2x.png" class="code_img" alt="">
            </div>
            <div class="benefitHeader">AI问答不限次数</div>
            <div class="benefitInfo">您将每天不限次数地使用升级版夏怡AI助手</div>
          </div>
          <div class="benefitDiv">
            <div class="benefitDiv_img">
              <img src="@/assets/img/Download@2x.png" class="code_img" alt="">
            </div>
            <div class="benefitHeader">高质量文档下载</div>
            <div class="benefitInfo">您将获得666次免积分下载高质量文档的权益</div>
          </div>
          <div class="benefitDiv">
            <div class="benefitDiv_img">
              <img src="@/assets/img/course@2x.png" class="code_img" alt="">
            </div>
            <div class="benefitHeader">部分课程限时免费</div>
            <div class="benefitInfo">您将拥有限时免费学习制定课程的机会</div>
          </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
          <div class="dialogTitle">会员充值</div>
          <div class="dialogText">购买会员请扫码微信联系</div>
          <div class="imgDiv" @click="courseClicked(item)">
            <img :src="vipCodePath" class="codeImg">
          </div>
          <!-- <span slot="footer" class="dialog-footer">
            <el-button class="btns" @click="dialogVisible = false">关闭</el-button>
          </span> -->
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { get_member_information } from "@/network/member";
export default {
  name: "Membership",
  data() {
    return {
      activeName: "following",
      followers: [],
      following: [],
      curView_page: [],
      currentPage: 1,
      pagesize: 10,
      files_count: 0,
      vipText: 'VIP 年卡送6个月',
      getVipText: '开通', //'开通' or '续费'
      nickname: '',
      membership: '',
      downloadTimes: 0,
      points: 0,
      level: 0,
      vipExpiration: '',
      dialogVisible: false,
      vipCodePath: require('@/assets/wechatCodeForVIP.jpeg'),
      notificationList: [
        '用户姚**已购买',
        '用户张**已购买',
        '用户小**已购买',
        '用户川**已购买',
        '用户峰**已购买',
        '用户飞**已购买',
        '用户歌**已购买',
        '用户好**已购买',
        '用户图**已购买',
        '用户依**已购买',
        '用户Jacob**已购买',
        '用户上**已购买',
        '用户刘**已购买',
        '用户如**已购买',
        '用户七**已购买',
        '用户堂**已购买',
        '用户Li**已购买',
        '用户汤**已购买',
        '用户Sepa**已购买',
        '用户郭**已购买',
        '用户欧**已购买',
        '用户夏**已购买',
        '用户Amy**已购买',
        '用户高**已购买',
      ]
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
  created() {
    this.email = this.$store.getters.email;
    get_member_information(this.email).then((res) => {
      if (res !== undefined && res !== null) {
        if (res.nickname !== undefined && res.nickname !== null) {
          this.nickname = res.nickname;
        }
        else {
          this.nickname = this.email;
        }
        this.membership = res.vip === 0 ? '普通用户' : '会员'; //1 是会员，0 不是会员
        this.getVipText = res.vip === 0 ? '开通' : '续费';
        this.downloadTimes = res.remaining_downloads;
        this.points = res.integral;
        this.level = res.grade;
        this.vipExpiration = res.due_time;
      }
    })
  }
}
</script>

<style scoped>
.dialogTitle {
  font-family: DingTalk JinBuTi, DingTalk JinBuTi;
  font-size: 30px;
  color: #C47D12;
}

:deep(.el-dialog) {
  background: linear-gradient(180deg, #FFF9F2 17%, #FFFEFD 100%);
  border-radius: 8px 8px 8px 8px;
}

:deep(.el-dialog__header) {
  padding: 0;
}

.imgDiv {
  margin-top: 20px;
}

.dialogText {
  color: #BD8B4E;
  margin-top: 20px;
  font-weight: bold;
}

.codeImg {
  width: 80%;
}

.benefitInfo {
  width: 70%;
  margin: 0 auto;
  color: #D8AA73;
  font-family: STFangsong, STFangsong;
  margin-top: 30px;
}

.benefitHeader {
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  font-family: STFangsong, STFangsong;
  color: #914F00;
  margin-top: 30px;
}

.benefitDiv {
  width: 25%;
  /* height: 250px; */
  background: linear-gradient(180deg, #FFF9F2 17%, #FFFEFD 100%);
  box-shadow: 1px 2px 8px 0px rgba(160, 160, 160, 0.25);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px 0;
}

.benefitDiv_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #FBEEDE;
  display: flex;
  align-items: center;
  justify-content: center;
}

.code_img {
  width: 30px;
}

.vipBenefitsText {
  margin: 10px 60px;
  font-family: STFangsong, STFangsong;
  border-radius: 10px;
  border: none;
  text-align: left;
  color: #914F00;
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 25px;
  font-weight: bold;
}

.infoInner {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topDiv,
.bottomDiv {
  margin: 0 50px 20px 50px;
  display: flex;
  flex: 1;
  gap: 25px;
}

.getVIPDiv {
  width: 60%;
  height: 300px;
  background: linear-gradient(180deg, #E9D7EF 0%, #FFFFFF 100%);
  box-shadow: inset 2px 2px 15px 0px rgba(255, 255, 255, 0.6);
  border-radius: 6px 6px 6px 6px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.getVIPDiv_shadow {
  position: absolute;
  top: -30px;
  right: -20px;
}

.vipTextDiv {
  font-family: DingTalk JinBuTi, DingTalk JinBuTi;
  font-size: 50px;
  font-weight: bold;
  align-self: center;
  margin: 0 auto;
  margin-top: 50px;
  color: #7A557B;
  position: relative;
}

.vipTextDiv_shadow {
  position: absolute;
  right: -5px;
  bottom: -5px;
  color: rgba(122, 85, 123, 0.2);
}

.break {
  flex-basis: 100%;
  height: 0;
}

.vipBtnDiv {
  margin: 0 auto;
}

.vipBtn {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  margin: 10px 0;
  font-size: 20px;
  background-color: rgba(175, 118, 177, 1);
  border-radius: 30px;
  color: #FFFFFF;
  padding: 15px 50px;
}

.vipBtn:hover {
  /* background-color: white; */
  border: 1px solid #7a557b;
  /* color: #7a557b; */
}

.vipInfoDiv {
  width: 40%;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  display: flex;
  padding: 40px 80px;
  flex-direction: column;
  justify-content: space-between;
}

.rowCenterDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.nameCol {
  justify-items: end;
}

.info {
  border-radius: 10px;
  border: 1px solid #c7c7c7;
  text-align: center;
  align-content: center;
  margin: 10px;
}

.breakColDiv {
  width: 100%;
}

.name {
  width: 60%;
  min-height: 40px;
}

.member {
  width: 40%;
  height: 40px;
}

.row23 {
  min-height: 35px;
  padding: 5px;
}

.downTimeText,
.downTime {
  width: 60%;
}

.points,
.pointsText {
  width: 25%;
}

.level,
.levelText {
  width: 15%;
}

.expiration {
  min-height: 35px;
  padding: 5px;
}

.expirationText {
  width: 40%;
}

.expirationDate {
  width: 60%;
}

.dynamicVal {
  color: #7a557b;
}

.staticText {
  background-color: #7a557b;
  color: #f3e6f6;
  border: none;
}

.btns {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  align-items: center;
  margin: 10px 0;
  color: #7a557b;
}

.btns:hover {
  background-color: #7a557b;
  color: #f3e6f6;
}

.home {
  overflow: hidden;
}

.homeBtn {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 30px;
  margin-left: 30px;
  padding: 0;
  top: 3px;
  left: 0;
}

.el-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #7a557b;
  z-index: 1000;
}

.mainButton {
  position: absolute;
  top: 0;
  margin-top: 0;
}

.backBtn {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  color: white;
  background-color: transparent;
  margin-top: 20px;
  left: 100px;
  border: none;
  font-size: 15px;
}

.app_header .el-button {
  color: white;
  margin: 10px;
}

.el-container {
  margin: 0px;
}

.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.belowTop {
  text-align: center;
  margin-top: 60px;
  height: calc(100vh - 60px);
  background: #F8F8F8;
}

.rowDiv {
  display: flex;
  justify-content: space-between;
}

.rowDiv_nickname {
  font-family: STFangsong, STFangsong;
  font-size: 30px;
  color: #333333;
}

.rowDiv_levelText {
  font-family: STFangsong, STFangsong;
  font-size: 20px;
  color: #333333;
  color: #666666;
}

.rowDiv_member {
  background: #F5F1F4;
  border-radius: 99px 99px 99px 99px;
  font-size: 14px;
  color: #666666;
  padding: 5px 10px;
}

.rowDiv_Text {
  font-size: 18px;
  color: #666666;
}

.member_img {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
}

.small {
  margin-left: 10px;
  text-align: left;
  color: #333333;
}

.notification {
  background: #FFFFFF;
}
</style>