<template>
  <div class="container">
    <el-container class="home">
      <el-page-header @back="goBack" content="DAWN - 经验交流" />
      <el-main class="belowTop">
        <div class="postDiv">
          <div class="postInner">
            <el-button v-if="isMyPost" class="postBtn deleteBtn" icon="el-icon-delete" @click="handleDelete()" />
            <el-button v-else class="postBtn followBtn" @click="toggleFollow()">
              {{ post.followedText }}
            </el-button>
            <div class="cardDiv postTitle">{{ post.file_name }}</div>
            <div class="cardDiv nickname"><el-button class="nameBtn" type="text" @click="viewUswer(post.ownerId)">{{
              post.nickname }}</el-button></div>
            <div class="cardDiv postTime">发布于 {{ post.create_date }}</div>
            <div class="cardDiv postContent">
              <!-- {{ post.content }} -->
              <v-md-preview :text="post.content"></v-md-preview>
            </div>
            <div class="comInfoDiv">
              <div class="comment"><i class="el-icon-chat-line-square"></i>评论 {{ post.commentNumber }}</div>
              <div v-if="isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-thumb"></i>赞 {{
                post.likeNumber }}</div>
              <el-button v-else class="postBtn likeBtn" icon="el-icon-thumb" @click="toggleLike()">{{ post.likeText }}
                {{ post.likeNumber }}</el-button>
              <div v-if="isMyPost" class="infoRight postInfo"><i class="el-icon-chat-line-star-off"></i>收藏 {{
                post.collectNumber }}</div>
              <el-button v-else class="postBtn collectBtn" icon="el-icon-star-off" @click="toggleCollect()">{{
                post.collectText }} {{ post.collectNumber }}</el-button>
            </div>
          </div>
          <div class="commentInputDiv">
            <el-input placeholder="输入开始评论" v-model="commentModel" clearable type="textarea" autosize
              class="inputComment" maxlength="800" show-word-limit>
            </el-input>
            <el-button class="postCommentBtn cmdBtn" type="text" @click="submitComment()">发布评论</el-button>
          </div>
          <div v-loading="loadingCm"
            style="background: #FFFFFF;border-radius: 3px 3px 3px 3px;border: 1px solid #DEDEDE;padding: 0 20px;">
            <el-card v-for="item in comments" :key="item.comId" :class="item.indent === 0 ? 'notIndent' : 'indent'">
              <div class="commentDiv commentor">
                <el-button class="nameBtn commentorNameBtn" type="text" @click="viewUswer(item.commentatorId)">{{
                  item.nickname }}</el-button>
                <span class="replyText">{{ item.replyText }}</span>
              </div>
              <span class="commentBtns commentTime">{{ item.create_date }}</span>
              <div class="commentDiv commentContent">{{ item.content }}</div>
              <el-button class="commentBtns postCommentBtn" v-if="isMyPost || item.isMyCom" type="text"
                @click="deleteComment(item)">删除</el-button>
              <el-button class="commentBtns postCommentBtn" type="text" @click="toggleReply(item)">
                <i v-if="item.replying" class="el-icon-caret-top"></i><i v-else class="el-icon-caret-bottom"></i>回复
              </el-button>
              <div class="commentInputDiv" v-if="item.replying">
                <el-input placeholder="回复此评论" v-model="replyCommentModel" clearable type="textarea" autosize
                  class="inputReply" maxlength="800" show-word-limit>
                </el-input>
                <el-button class="postCommentBtn" style="margin-left: 10px;" type="text" @click="replyComment(item)">发布回复</el-button>
              </div>
            </el-card>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import {
  get_all_comments, collect_post, uncollect_post, like_post, unlike_post,
  make_comment, follow_user, unfollow_user, delete_post, delete_comment
} from "@/network/community";
export default {
  name: "CommunityPost",
  data() {
    return {
      isMyPost: false,
      replying: false,
      commentModel: '',
      replyCommentModel: '',
      post: {
        cardId: 1, file_name: '', nickname: '', create_date: '', content: '', isFollow: 0, followedText: '+关注',
        collectNumber: 0, likeNumber: 0, isCollect: 0, isLiked: 0, likeText: '点赞', collectText: '收藏', commentNumber: 0
      },
      comments: [],
      curPostId: -1,
      email: '',
      commentIds: {},
      loadingCm: true,
    };
  },
  methods: {
    handleDelete() {
      event.stopPropagation();
      this.$confirm('是否要删除帖子', '系统提示', {
        confirmButtonText: '确认',
        cancelButtonText: '返回',
        type: 'info'
      }).then(() => {
        delete_post(this.email, this.post.cardId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已删除帖子'
            });
          }
          else {
            this.$message({
              type: 'warning',
              message: '删除帖子失败，请稍后重试'
            });
          }
        })
      }).catch(() => { });
      document.activeElement.blur();
    },
    submitComment() {
      if (this.commentModel.trim() === "") {
        this.$message({
          type: 'warning',
          message: '不能发布空白评论'
        });
      }
      else {
        this.post_comment(0, this.commentModel);
      }
    },
    replyComment(item) {
      if (this.replyCommentModel.trim() === "") {
        this.$message({
          type: 'warning',
          message: '不能发布空白回复'
        });
      }
      else {
        this.post_comment(item.comId, this.replyCommentModel);
      }
    },
    viewUswer(uid) {
      this.$router.push({
        name: 'posts_view', params: {
          uid: uid,
        }
      });
    },
    toggleReply(item) {
      item.replying = item.replying ? false : true;
      this.$forceUpdate();
    },
    post_comment(father_node, comment) {
      let message_success = (father_node === 0) ? '评论已发布' : '回复已发布';
      let message_failed = (father_node === 0) ? '评论发布失败，请稍后重试' : '回复发布失败，请稍后重试';
      make_comment(this.email, this.post.cardId, this.post.ownerId, comment, father_node).then((res) => {
        if (res.flag === 1) {
          this.$message({
            type: 'success',
            message: message_success
          });
          if (father_node === 0) {
            this.commentModel = '';
          }
          else {
            this.replyCommentModel = '';
          }
          this.post.commentNumber += 1;
          this.getComments();
        }
        else {
          this.$message({
            type: 'warning',
            message: message_failed
          });
        }
      })
    },
    deleteComment(item) {
      delete_comment(this.email, this.post.cardId, this.post.ownerId, item.comId).then((res) => {
        if (res.flag === 1) {
          this.$message({
            type: 'success',
            message: '评论已删除'
          });
          this.post.commentNumber -= 1;
          this.getComments();
        }
        else {
          this.$message({
            type: 'warning',
            message: '评论删除失败，请稍后重试'
          });
        }
      })
    },
    toggleFollow() {
      event.stopPropagation();
      if (this.post.isFollow === 0) {
        this.post.followedText = (this.post.isFollow === 1) ? '+关注' : '已关注';
        this.post.isFollow = 1;
        follow_user(this.email, this.post.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已关注'
            });
          }
          else {
            this.post.followedText = (this.post.isFollow === 1) ? '+关注' : '已关注';
            this.post.isFollow = 0;
            this.$message({
              type: 'warning',
              message: '取消关注失败，请稍后重试'
            });
          }
        })
      }
      else {
        this.post.followedText = (this.post.isFollow === 1) ? '+关注' : '已关注';
        this.post.isFollow = 0;
        unfollow_user(this.email, this.post.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已取消关注'
            });
          }
          else {
            this.post.followedText = (this.post.isFollow === 1) ? '+关注' : '已关注';
            this.post.isFollow = 1;
            this.$message({
              type: 'warning',
              message: '取消关注失败，请稍后重试'
            });
          }
        })
      }
      document.activeElement.blur();
      this.$forceUpdate();
      console.log(this.post.followedText);
    },
    toggleLike() {
      event.stopPropagation();
      this.post.likeText = this.post.likeText === '已赞' ? '点赞' : '已赞';
      if (this.post.isLiked === 0) {
        this.post.isLiked = 1;
        this.post.likeNumber++;
        like_post(this.email, this.post.cardId, this.post.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已点赞'
            });
          }
          else {
            this.post.isLiked = 0;
            this.post.likeNumber--;
            this.post.likeText = this.post.likeText === '已赞' ? '点赞' : '已赞';
            this.$message({
              type: 'warning',
              message: '取消点赞失败，请稍后重试'
            });
          }
        })
      }
      else {
        this.post.isLiked = 0;
        this.post.likeNumber--;
        unlike_post(this.email, this.post.cardId, this.post.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已取消点赞'
            });
          }
          else {
            this.post.isLiked = 1;
            this.post.likeNumber++;
            this.post.likeText = this.post.likeText === '已赞' ? '点赞' : '已赞';
            this.$message({
              type: 'warning',
              message: '取消点赞失败，请稍后重试'
            });
          }
        })
      }
      document.activeElement.blur();
    },
    toggleCollect() {
      event.stopPropagation();
      this.post.collectText = this.post.collectText === '已收藏' ? '收藏' : '已收藏';
      if (this.post.isCollect === 0) {
        this.post.isCollect = 1;
        this.post.collectNumber++;
        collect_post(this.email, this.post.cardId, this.post.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已收藏'
            });
          }
          else {
            this.post.isCollect = 0;
            this.post.collectNumber--;
            this.post.collectText = this.post.collectText === '已收藏' ? '收藏' : '已收藏';
            this.$message({
              type: 'warning',
              message: '收藏失败，请稍后重试'
            });
          }
        })
      }
      else {
        this.post.isCollect = 0;
        this.post.collectNumber--;
        uncollect_post(this.email, this.post.cardId, this.post.ownerId).then((res) => {
          if (res.flag === 1) {
            this.$message({
              type: 'success',
              message: '已取消收藏'
            });
          }
          else {
            this.post.isCollect = 1;
            this.post.collectNumber++;
            this.post.collectText = this.post.collectText === '已收藏' ? '收藏' : '已收藏';
            this.$message({
              type: 'warning',
              message: '取消收藏失败，请稍后重试'
            });
          }
        })
      }
      // item.isCollect = item.isCollect === 1 ? 0 : 1;
      document.activeElement.blur();
    },
    getComments() {
      let prevFatherCom = -1;
      get_all_comments(this.email, this.curPostId).then((res) => {
        if (res !== undefined && res.data !== undefined) {
          this.comments = res.data; //后端数据查询时以控制有序
          this.comments.forEach(cm => {
            cm['replying'] = false; //回复框默认隐藏
            cm['replyText'] = ((prevFatherCom !== -1 && prevFatherCom !== cm.father_node) && (cm.father_node !== 0)) ? " 回复 " + cm.father_nickname : "";
            prevFatherCom = (cm.father_node === 0) ? cm.comId : prevFatherCom;
            cm['isMyCom'] = (cm.commentatorId === this.email) ? true : false;
          });
        }
        else {
          this.comments = [];
        }
        this.loadingCm = false;
      }).catch((error) => {
        console.log(error);
      })
    },
    goBack() {
      this.$router.back();
    }
  },
  created() {
    this.email = this.$store.getters.email;
    if (this.$route.params.post === undefined || this.$route.params.post === null) {
      this.post = this.$store.getters.post;
    }
    else {
      this.post = this.$route.params.post;
      this.$store.commit("SET_POST", this.post);
    }
    this.curPostId = this.post.cardId;
    if (this.post.ownerId === this.email) { //我的帖子
      this.isMyPost = true;
    }
    this.getComments();
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.el-page-header {
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 20px;
  width: 100%;
  height: 60px;
  justify-content: start;
  align-items: center;
  background-color: #7a557b;
  color: white;
  overflow: hidden;
}

::v-deep .el-page-header__content {
  color: white;
}

.el-main {
  /* background-color: #f3e6f6; */
  color: #333;
  text-align: center;
  flex: 1;
}

.belowTop {
  margin-top: 60px;
  flex: 1;
  height: calc(100vh - 60px);
}

.commentInputDiv {
  margin: 0 auto;
  margin-bottom: 10px;
  justify-content: start;
  display: flex;
}

.commentInputDiv ::v-deep .el-textarea__inner {
  border-radius: 10px;
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
}

.el-textarea ::v-deep .el-input__count {
  margin-bottom: 10px;
}

.inputComment {
  width: 95%;
}

.inputReply {
  width: 90%;
  margin-top: 10px;
}

::v-deep .el-textarea__inner:focus {
  border-color: #9c7dae;
}

.el-button {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
}

.postDiv {
  max-width: 70%;
  margin: 0 auto;
}

.cmdBtn {
  float: right;
  margin-left: 10px;
  background: #7A557B;
  border-radius: 2px 2px 2px 2px;
  color: #FFFFFF !important;
  padding: 0 20px;
  height: 40px;
}

.postCommentBtn {
  font-size: 13px;
  margin-right: 10px;
  color: #7a557b;
}

.postCommentBtn:hover {
  color: #f3e6f6;
}

/* Posts CSS */
.comInfoDiv {
  height: 50px;
}

.cardDiv {
  overflow: hidden;
  text-align: start;
}

.indent {
  margin-left: 40px;
}

.el-card {
  text-align: start;
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid #DEDEDE;
}

.postBtn {
  align-items: center;
  padding: 5px;
  float: right;
  font-size: small;
  border: 1px#7a557b;
  height: 30px;
  width: 60px;
  margin: 10px 0;
  color: #7a557b;
}

.followBtn {
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #7A557B;
  margin-right: 30px;
}

.followBtn:hover {
  background-color: #7A557B;
  border: 0;
  color: #f3e6f6;
}

.deleteBtn:hover {
  background-color: transparent;
  border: 0;
  color: #f3e6f6;
}

.likeBtn {
  width: 80px;
  border: 1px #7a557b;
}

.likeBtn:focus {
  background-color: white !important;
  border: 0;
}

.likeBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}

.collectBtn {
  width: 80px;
  margin-right: 5px;
  border: 1px #7a557b;
}

.collectBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}

.postTitle {
  font-family: STFangsong, STFangsong;
  font-size: 25px;
  margin-bottom: 20px;
  color: #3D3D3D;
}

.nickname {
  color: #3D3D3D;
  margin-top: 5px;
  font-size: 14px;
  font-family: STFangsong, STFangsong;
}

.postTime {
  font-family: STFangsong, STFangsong;
  color: #3D3D3D;
  margin-top: 10px;
  font-size: 14px;
}

.postContent {
  font-family: STFangsong, STFangsong;
  color: #3D3D3D;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 40px;
  white-space: pre-wrap;
  /* line-height: 1.6; */
  /* padding: 20px 0; */
  background: #FBFAFB;
  border-radius: 3px 3px 3px 3px;
}

.infoRight {
  float: right;
  margin-left: 10px;
}

.postInfo {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-size: small;
  height: 25px;
  color: #7a557b;
  align-content: center;
  color: #333;
}

.commentDiv {
  font-size: medium;
}

.commentContent {
  margin-top: 15px;
  flex-wrap: wrap;
  display: flex;
  word-break: break-all;
  line-height: 1.6;
}

.commentTime {
  font-size: 11px;
}

::v-deep .nameBtn,
.commentorNameBtn {
  font-style: italic;
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  color: #7a557b;
}

.commentorNameBtn {
  padding: 0px;
}

.replyText {
  font-style: normal;
}

.commentor,
.commentContent {
  width: 80%;
}

.commentContent {
  white-space: pre-wrap;
}

.comment {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
  font-size: small;
  margin-top: 16px;
  color: #7a557b;
  align-content: center;
  float: left;
  color: #333;
}

.commentBtns {
  float: right;
  margin-bottom: 10px;
}

.el-icon-chat-line-square {
  padding-right: 5px;
}

::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
  font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
}

::v-deep .el-input__inner:focus,
::v-deep .el-textarea__inner:focus {
  border-color: #7a557b;
}
</style>