<template>
    <div style="text-align: center">
        <div class="search">
            <el-input placeholder="搜索专业" v-model="major_name" @keyup.enter.native="handleMajorSearch" clearable
                @clear="handleMajorSearch" suffix-icon="el-icon-search"></el-input>
            <el-input placeholder="搜索院所" v-model="keywords" @keyup.enter.native="handleSearch" clearable
                @clear="handleSearch" suffix-icon="el-icon-search"></el-input>
        </div>
        <el-table :data="tableData_page" v-loading="loading" @row-click="rowClicked"
            @selection-change="handleSelectionChange" class="clickable-rows">
            <el-table-column v-if="selectingMultiple" type="selection" width="50"></el-table-column>
            <el-table-column prop="cname" label="学校名称"></el-table-column>
            <el-table-column prop="area" label="地区"></el-table-column>
            <el-table-column prop="is985" label="是否985" width="70">
                <template slot-scope="scope">
                    {{ scope.row.is985 ? "是" : "否" }}
                </template>
            </el-table-column>
            <el-table-column prop="is211" label="是否211" width="70">
                <template slot-scope="scope">
                    {{ scope.row.is211 ? "是" : "否" }}
                </template>
            </el-table-column>
            <el-table-column prop="isDFC" label="是否双一流" width="90">
                <template slot-scope="scope">
                    {{ scope.row.isDFC ? "是" : "否" }}
                </template>
            </el-table-column>
            <el-table-column prop="level" label="办学层次"></el-table-column>
            <el-table-column prop="type" label="院校类别"></el-table-column>
            <el-table-column prop="property" label="办学性质"></el-table-column>
            <el-table-column prop="star" label="保研星级"></el-table-column>
        </el-table>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[5, 10, 15]" :page-size="pagesize"
            layout="total,jumper,prev, pager, next,sizes" :total="files_count">
        </el-pagination>
    </div>
</template>

<script>
import { get_college_information, get_college_major_like } from "@/network/home";

export default {
    name: "training_units",
    data() {
        return {
            tableDataAll: [
                {
                    "cname": "加载中",
                    "area": "加载中",
                    "is985": "加载中",
                    "is211": "加载中",
                    "isDFC": "加载中",
                    "level": "加载中",
                    "type": "加载中",
                    "property": "加载中",
                    "star": "加载中",
                }
            ],
            tableData: [
                {
                    "cname": "加载中",
                    "area": "加载中",
                    "is985": "加载中",
                    "is211": "加载中",
                    "isDFC": "加载中",
                    "level": "加载中",
                    "type": "加载中",
                    "property": "加载中",
                    "star": "加载中",
                }
            ],
            tableData_page: [
                {
                    "cname": "加载中",
                    "area": "加载中",
                    "is985": "加载中",
                    "is211": "加载中",
                    "isDFC": "加载中",
                    "level": "加载中",
                    "type": "加载中",
                    "property": "加载中",
                    "star": "加载中",
                }
            ],
            selectingMultiple: false,
            selected: [],
            currentPage: 1,
            pagesize: 10,
            files_count: 0,
            keywords: '',
            loading: true,
            major_name: '',
        }
    },
    methods: {
        handleSearch() {
            this.tableData = this.tableDataAll.filter((p) => {
                return p.cname.indexOf(this.keywords) !== -1;
            })
            this.hadleGetFilesListApi();//刷新页面
        },
        //分页 初始页currentPage、初始每页数据数pagesize和数据testpage--->控制每页几条
        handleSizeChange: function (size) {
            this.pagesize = size;
            this.hadleGetFilesListApi();
            console.log(this.pagesize);
        },
        // 控制页面的切换
        handleCurrentChange: function (currentPage) {
            this.currentPage = currentPage;
            this.hadleGetFilesListApi();
        },
        //对所有数据进行分页处理 发送请求
        hadleGetFilesListApi() {  //刷新页面
            this.tableData_page = this.tableData.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize);
            this.files_count = this.tableData.length;
        },
        // selectMultipleClicked() {
        //     //显示/隐藏 批量操作勾选框
        //     this.selectingMultiple = !this.selectingMultiple;
        // },
        handleSelectionChange(selection) {
            this.selected = selection;
        },
        rowClicked(row) {
            console.log(row);
            this.$router.push({
                path: '/university_detail',
                query: {
                    university: JSON.stringify(row.cname), // 如果需要传递对象，可以将其序列化为 JSON 字符串
                }
            })
        },
        getCollegeMajorLike() {
            get_college_major_like({ email: this.$store.getters.email, major_name: this.major_name }).then(res => {
                console.log(res);
                this.tableDataAll = res.results;
                this.loading = false;
            })
        },
        handleMajorSearch() {
            this.loading = true;
            this.getCollegeMajorLike();
        }
    },
    beforeCreate() {
        // get_departments(this.$store.getters.email).then((res) => {
        //     this.tableDataAll = res.files;
        //     this.loading = false;
        // })
        get_college_information({ email: this.$store.getters.email }).then(res => {
            // console.log(res);
            this.tableDataAll = res.results;
            this.loading = false;
        })
    },
    watch: {
        tableDataAll: {
            handler: function () {
                this.tableData = this.tableDataAll;
                this.tableData_page = this.tableData.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize);
                this.files_count = this.tableData.length;
            },
            deep: true,
            immediate: true
        }
    },
}
</script>

<style scoped>
.clickable-rows {
    width: 100%;
    height: 80%;
    margin-top: 20px;
    cursor: default;
    border-style: solid;
    border-color: #09073c;
}

.el-table {
    border: 0;
}

.el-pagination {
    margin: 20px;
}

#collectBtn {
    border: 0px;
    background-color: transparent;
    color: #7a557b;
    font-size: 17px;
}

#collectBtn:hover {
    background-color: transparent;
    color: #d6c5db;
}

.search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.search .el-input{
    width: auto;
    margin-right: 30px;
}

.search ::v-deep .el-input__inner {
    height: 40px;
    border-radius: 40px;
    width: 300px;
    float: right;
    font-family: 'STFangsong', 'FangSong', 'STSong', Helvetica, Arial, sans-serif;
}
</style>