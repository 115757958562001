import request from '@/utils/request'

// 获取用户所有通知
export function get_report(email, form) {
    const data = {
        email: email,
        form: form,
    }
    return request({
        url: '/intelligence/get_position_report',
        method: 'get',
        params: data,
        responseType: 'blob'
    }).then(res => {
        let blob = new Blob([res.data], { type: 'application/pdf' });
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob)
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        const contentDisposition = res.headers['content-disposition'] || 'attachment;filename=report';
        tempLink.setAttribute('download', decodeURI(contentDisposition.split(';')[1].split('=')[1]))
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
    });
}

//获取报告的个人情况模块的正文信息
export function get_report_body(email, form) {
    const data = {
        email: email,
        form: form,
    }
    return request({
        url: '/intelligence/get_personal_situation',
        method: 'post',
        data: data,
    })
}

//获取报告的院校推荐模块的正文信息
export function get_report_college(email, form) {
    const data = {
        email: email,
        form: form,
    }
    return request({
        url: '/intelligence/get_recommended_university',
        method: 'post',
        data: data,
    })
}

//获取报告的提升建议模块的正文信息
export function get_report_suggestion(email, form) {
    const data = {
        email: email,
        form: form,
    }
    return request({
        url: '/intelligence/get_suggestions_for_improvement',
        method: 'post',
        data: data,
    })
}

// 获取该用户的历史窗口
// reutrn: windowId: int
export function get_history_window(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/intelligence/get_history_window',
        method: 'post',
        data: data,
    })
}

// 创建新的窗口
// reutrn: windowId: int
export function crete_new_window(email) {
    const data = {
        email: email,
    }
    return request({
        url: '/intelligence/create_new_window',
        method: 'post',
        data: data,
    })
}

// 创建新的窗口
// reutrn: windowId: int
export function delete_chat_window(email, windowId) {
    const data = {
        email: email,
        windowId: windowId
    }
    return request({
        url: '/intelligence/delete_appoint_window',
        method: 'post',
        data: data,
    })
}

// 获取该用户指定历史窗口的所有对话
// reutrn: dialogue: int
export function get_chats(email, windowId) {
    const data = {
        email: email,
        windowId: windowId
    }
    return request({
        url: '/intelligence/get_appoint_window',
        method: 'post',
        data: data,
    })
}

// 与AI 对话
// reutrn: answer: string
export function ask_question(email, question, typeId, windowId) {
    const data = {
        email: email,
        question: question,
        typeId: typeId,
        windowId: windowId,
    }
    return request({
        url: '/intelligence/ai_dialogue',
        method: 'post',
        data: data,
    })
}

//上传文件问答
export function ai_upload_file(formdata, config) {
    return request({
        url: '/intelligence/ai_dialogue_file',
        method: 'post',
        data: formdata,
        headers:config.headers
    })
}

//获取所有学校列表
export function get_college_list(data) {
    return request({
        url: '/intelligence/get_college_list',
        method: 'post',
        data: data,
    })
}

//获取所有专业列表
export function get_major_list(data) {
    return request({
        url: '/intelligence/get_major_list',
        method: 'post',
        data: data,
    })
}