<template>
    <div class="report">
        <div class="report-box" id="view" ref="result" style="width: 840px">
            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/title-1@2x.png" class="a4_title" alt="">
                <div class="a4_title_en">ZHONG KE FANG QIU</div>
                <img src="../assets/img/polygon 1@2x.png" class="a4_polygon1" alt="">
                <img src="../assets/img/bg 1@2x.png" class="a4_bg" alt="">
                <img src="../assets/img/polygon 2@2x.png" class="a4_polygon2" alt="">
                <img src="../assets/img/rectangle 1@2x.png" class="a4_rectangle" alt="">
                <img src="../assets//img/REPORT@2x.png" class="a4_report" alt="">
                <div class="a4_content">
                    <div class="a4_content_title">大学生卓越计划</div>
                    <div class="a4_content_title">保研深度定位报告</div>
                    <div style="margin-top: 10px;">有理想、有信念、</div>
                    <div>有目标、有责任</div>
                </div>
                <div class="a4_footer">
                    {{ userName }}同学保研专属定制方案
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <h1>引言 保研之路：梦想启航，铸就辉煌</h1>
                        <div class="a4_mian_content_text">
                            <p>保研，是学术征途上的一枚璀璨勋章，它不仅是对过往努力的认可，更是未来探索的金色钥匙。在这条少有人走的路上，每一步都镌刻着汗水与智慧的光芒。想象一下，当同龄人还在为考研的硝烟奋战时，你已手握保研的通行证，悠然步入心仪学府的殿堂，那份从容与自信，足以照亮梦想的天空。
                            </p>
                            <p>保研，是智慧与坚持的交响曲，它呼唤着每一位怀揣学术梦想、勇于攀登高峰的你。这不仅仅是一场关于分数的较量，更是对科研热情、创新能力、综合素质的全面考验。它激励你深挖专业潜能，拓宽国际视野，让每一次实验、每一篇论文都成为通往卓越之路的坚实基石。
                            </p>
                            <p>同学们，让我们以梦为马，不负韶华，用汗水浇灌希望，以智慧点亮未来。保研之路，虽非坦途，却风景无限。勇敢地迈出这一步，你会发现，梦想的彼岸，正以最温柔的姿态，迎接每一个不懈奋斗的灵魂。
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <h1>一、保研概况：现状、竞争、趋势与优势</h1>
                        <div class="a4_mian_content_text">
                            <p>保研，作为高等教育体系中一种重要的研究生选拔方式，近年来受到了越来越多的关注。其现状呈现出多元化、规范化的特点，越来越多的高校开始重视保研生的综合素质和创新能力，而非仅仅依赖于学业成绩。这不仅提高了保研的门槛，也使得保研竞争愈发激烈。
                            </p>
                            <h3>1. 保研的基本政策、条件与流程</h3>
                            <p>保研，即推荐免试研究生，是高校选拔优秀应届本科毕业生免试攻读硕士学位研究生的一种制度。其基本政策、条件与流程如下：
                            </p>
                            <p>保研政策旨在选拔德智体美劳全面发展的优秀本科毕业生，为研究生阶段的教育培养奠定坚实基础。政策每年都会根据教育部的指导方针和高校的实际需求进行调整，以确保选拔的公正性和科学性。
                            </p>
                            <p>保研条件通常包括：必须是应届毕业生，成绩优秀，具有较强的创新意识和专业能力，诚实守信无学术不端记录，品行优良无违法违纪处分。此外，部分高校还将体测和英语成绩纳入保研考核标准，进一步提升保研的综合素质要求。
                            </p>
                            <p>保研流程一般分为三个阶段：首先是学生申请，学生需要按照学校的要求提交相关申请材料，包括成绩单、科研论文、获奖证书等；其次是学院综合评价，学院会对这些材料进行综合评价，并结合学生的平时表现，确定推免生名单；最后是学校审定，学校会对学院上报的名单进行审定，并公示最终结果。
                            </p>
                            <p>在整个保研过程中，学生需要密切关注学校发布的保研政策和通知，了解最新的申请条件和流程。同时，学生还需要注重平时的学习表现和学术积累，努力提升自己的学术成绩和科研能力。此外，积极参加各类竞赛、社会实践和志愿服务等活动，也能为保研增添亮点。
                            </p>
                            <p>保研不仅是对学生本科阶段学术表现和综合素质的认可，也是学生继续深造、追求更高学术目标的重要途径。通过保研，学生可以提前锁定研究生入学资格，为未来的学术生涯打下坚实的基础。
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <p>近年来，保研作为高校选拔优秀本科毕业生免试攻读研究生的重要途径，其趋势呈现出一些显著的变化。以下是对近年来保研趋势的分析，包括保研名额的变化和保研难度的增加等方面。
                            </p>
                            <h3>2. 保研的竞争</h3>
                            <p>保研的竞争主要体现在两方面：一是保研名额的有限性，使得众多优秀学子不得不进行激烈的角逐；二是保研标准的不断提高，对学生的科研能力、社会实践、国际视野等方面都提出了更高的要求。这种竞争态势，既是对学生综合能力的考验，也是高校选拔优秀人才的重要途径。
                            </p>
                            <h4>2.1 保研名额的变化</h4>
                            <p>近年来，保研名额呈现出稳步增长的趋势。教育部对保研名额的分配主要依据高校类型和研究生培养能力，985、211高校占据保研名额的大头，保研率通常在10%至15%之间，部分顶尖高校甚至能达到20%。相比之下，非211的普通高校的保研名额较少，保研率多在2%至5%之间。然而，随着国家对研究生教育投入的加大和高校办学水平的提升，保研名额整体呈现扩增态势。
                            </p>
                            <h4>2.2 保研难度的增加</h4>
                            <p>尽管保研名额有所增加，但保研的竞争却日益激烈，保研难度也在不断提高。这主要体现在以下几个方面：
                            </p>
                            <p>1. 学业成绩要求提高：为了选拔出更优秀的本科生，高校对保研生的学业成绩要求越来越高。学生需要在大学期间保持优异的成绩排名，才能获得保研资格。
                            </p>
                            <p>2. 科研能力竞争加剧：除了学业成绩，科研能力也是保研考核的重要指标之一。学生需要积极参与科研项目、发表论文等，以展示自己的科研潜力和创新能力。
                            </p>
                            <p>3.
                                综合素质要求提升：近年来，高校越来越注重学生的综合素质，包括组织协调能力、团队合作能力、社会实践能力等。这些能力的提升需要学生在大学期间积极参与各类社会实践活动和志愿服务活动。
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>3. 保研的好处</h3>
                            <p>保研的好处显而易见。它不仅为学生提供了直接进入研究生阶段学习的机会，避免了考研的艰辛，还为学生提供了更多的学术资源和研究平台，有助于培养其科研能力和创新精神。此外，保研生往往能更早地接触和参与到导师的科研项目中，为未来的学术研究和职业发展奠定坚实的基础。
                            </p>
                            <h3>4. 小结</h3>
                            <p>保研既是一种挑战，也是一种机遇。近年来，保研趋势呈现出名额稳步增长但竞争日益激烈的特点，要求学生不断提升自我，追求卓越，包括学业成绩、科研能力和综合素质，以在保研竞争中脱颖而出。保研不仅为学生提供了更广阔的发展空间和更多的可能性，还预示着未来将更加注重学生的创新能力和实践经验，拥有科研成果、社会实践经历、国际交流背景的学生将更具优势。
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_title">二、个人情况</div>
                        <div class="a4_mian_content_text">
                            <h3>1. 本科学校</h3>
                            <p>{{ userInfoContent.UniversityInformation }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>2. 专业</h3>
                            <h4>2.1 专业介绍</h4>
                            <p>{{ userInfoContent.MajorInformation }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h4>2.2 专业发展趋势</h4>
                            <p>{{ userInfoContent.MajorTrend }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>3. 学业成绩</h3>
                            <p>绩点在保研过程中扮演着至关重要的角色。它是衡量一个学生在本科阶段学习成绩和学术表现的主要指标，直接反映了学生的专业基础和学术能力。高校在选拔保研生时，通常会根据学生的绩点进行排名，绩点高的学生更有机会获得保研资格。此外，绩点也是导师和招生委员会评估学生综合素质和潜力的重要依据。一个优秀的绩点不仅能够增加学生获得保研机会的可能性，还能为学生在争取心仪导师和研究方向时增添筹码。因此，学生在本科阶段应该努力学习，争取取得较高的绩点，为未来的保研之路打下坚实的基础。同时，也要注重提升自己的综合素质和能力，以全面提升自己的竞争力。
                            </p>
                            <p>{{ userInfoContent.gradeContent }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>4. 英语水平</h3>
                            <p>英语水平在保研过程中同样占据着不可忽视的重要地位。随着全球化的加速和学术交流的日益频繁，英语已成为衡量学生国际视野和跨文化交流能力的重要指标。在保研面试和申请材料中，英语水平的高低往往直接影响到导师和招生委员会对学生的评价。具备良好的英语听说读写能力，不仅能够帮助学生更好地阅读和理解英文文献，提升科研能力，还能在国际学术会议和学术交流中更加自信地展示自己。此外，一些高校和科研机构在选拔保研生时，也会对学生的英语水平提出明确要求，如通过英语六级或具备相应的英语能力证明。因此，学生在保研准备过程中，应注重提升自己的英语水平，以增加自己的竞争力和适应能力。
                            </p>
                            <p>{{ userInfoContent.EnglishContent }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>5. 科研水平</h3>
                            <p>科研水平在保研过程中具有举足轻重的地位。它不仅体现了学生对专业知识的深入理解和应用能力，更是衡量学生创新思维和解决问题能力的重要标尺。在保研的选拔过程中，拥有较高科研水平的学生往往能够展示出自己在学术研究上的潜力和成果，这不仅能够增加他们在面试中的竞争力，还能为他们在争取心仪导师和研究方向时提供有力的支持。此外，许多高校和科研机构在选拔保研生时，会特别关注学生的科研经历和成果，将其视为评估学生综合素质和未来发展潜力的重要依据。因此，对于有意保研的学生而言，积极参与科研项目，提升自己的科研水平，是他们在保研过程中不可或缺的一环。
                            </p>
                            <p>{{ userInfoContent.scientificResearchContent }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>6. 竞赛水平</h3>
                            <p>竞赛水平在保研过程中同样具有重要的影响力。它不仅是衡量学生实践能力、创新思维和团队协作能力的重要指标，也是展示学生综合素质和个性风采的有效途径。在保研的选拔过程中，具备较高竞赛水平的学生往往能够凭借在各类竞赛中获得的荣誉和成绩，脱颖而出，吸引导师和招生委员会的注意。这些竞赛经历不仅丰富了学生的简历，还体现了他们在面对挑战时的应变能力和解决问题的能力。此外，竞赛水平的高低也往往与学生的专业兴趣、学习动力和职业规划密切相关，能够为学生保研后的学术研究和职业发展奠定坚实的基础。因此，积极参与各类竞赛，提升自己的竞赛水平，对于有意保研的学生而言，无疑是一个重要的加分项。
                            </p>
                            <p>{{ userInfoContent.contestContent }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>7. 项目经历</h3>
                            <p>科研型项目和开发型项目在多个维度上存在显著差异。科研型项目主要侧重于探索未知领域、解决尚未解决的问题，并寻求新的科学知识和创新方法。这类项目通常具有高度的探索性和创造性，但同时也伴随着较大的不确定性和风险性。科研项目的成果往往是隐性的，如新知识、新理论或新思想，难以直接量化或衡量。相比之下，开发型项目则更注重将已有的科学知识和技术应用于实际问题的解决中，以实现具体的目标或产生实际效益。这类项目通常具有明确的目标和成果要求，并且更加注重实用性和经济性。开发型项目的成果往往可以直接量化，如新产品、新工艺或新服务的产生，以及由此带来的经济效益和社会效益。因此，科研型项目和开发型项目在目标、过程、成果和应用等方面都存在明显的区别，两者在科研和产业发展中各自扮演着不可或缺的角色。
                            </p>
                            <p>项目经历在保研竞争中占据着举足轻重的地位。它不仅体现了学生在专业领域的实践能力和探索精神，还是评估学生综合素质和解决问题能力的重要依据。丰富的项目经历能够为学生增添亮点，使他们在保研选拔中脱颖而出。通过参与项目，学生能够积累宝贵的实践经验，提升专业技能，培养团队协作和解决问题的能力。这些经历在保研面试和申请材料中，都能为学生赢得导师和招生委员会的青睐，增加保研成功的几率。
                            </p>
                            <p>{{ userInfoContent.projectContent }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always; width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>8. 社会实践</h3>
                            <p>大学生社会实践包括志愿服务、社会调研、专业实习、创新创业等多种形式，旨在增强学生的社会责任感、实践能力和创新思维。社会实践在保研竞争中扮演着重要角色，它不仅丰富了学生的综合素质评价，还展现了学生将理论知识应用于实际问题的能力，是评估学生全面发展和潜在科研能力的重要依据。
                            </p>
                            <p>{{ userInfoContent.practiceContent }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_title">三、院校推荐</div>
                        <div class="a4_mian_content_text">
                            <div v-if="university.targets.length > 0">
                                <div v-if="university.targets[0].name != ''">
                                    <div class="a4_mian_school_title">1.1 {{ university.targets[0].name }}</div>
                                    <p>{{ university.targets[0].content }}</p>
                                </div>
                                <div v-if="university.targets[1].name != ''">
                                    <div class="a4_mian_school_title">1.2 {{ university.targets[1].name }}</div>
                                    <p>{{ university.targets[1].content }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.targets.length > 0 && university.targets[2].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <div v-if="university.targets[2].name != ''">
                                <div class="a4_mian_school_title">1.3 {{ university.targets[2].name }}</div>
                                <p>{{ university.targets[2].content }}</p>
                            </div>
                            <div v-if="university.targets[3].name != ''">
                                <div class="a4_mian_school_title">1.4 {{ university.targets[3].name }}</div>
                                <p>{{ university.targets[3].content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.targets.length > 0 && university.targets[4].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <div v-if="university.targets[4].name != ''">
                                <div class="a4_mian_school_title">1.5 {{ university.targets[4].name }}</div>
                                <p>{{ university.targets[4].content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>2. 学生定位</h3>
                            <h4>2.1 冲刺院校</h4>
                            <div v-if="university.firstTargets.length > 0">
                                <div class="a4_mian_school_title">{{ university.firstTargets[0].name }}</div>
                                <p>{{ university.firstTargets[0].content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.firstTargets.length > 1 && university.firstTargets[1].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <div class="a4_mian_school_title">{{ university.firstTargets[1].name }}</div>
                            <p>{{ university.firstTargets[1].content }}</p>
                            <div class="a4_mian_school_title" v-if="university.firstTargets.length > 2">{{
                                university.firstTargets[2].name }}</div>
                            <p v-if="university.firstTargets.length > 2">{{ university.firstTargets[2].content }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.firstTargets.length > 3 && university.firstTargets[3].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <div class="a4_mian_school_title">{{ university.firstTargets[3].name }}</div>
                            <p>{{ university.firstTargets[3].content }}</p>
                            <div class="a4_mian_school_title" v-if="university.firstTargets.length > 4">{{
                                university.firstTargets[4].name }}</div>
                            <p v-if="university.firstTargets.length > 4">{{ university.firstTargets[4].content }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.firstTargets.length > 5 && university.firstTargets[5].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <div class="a4_mian_school_title">{{ university.firstTargets[5].name }}</div>
                            <p>{{ university.firstTargets[5].content }}</p>
                            <div class="a4_mian_school_title" v-if="university.firstTargets.length > 6">{{
                                university.firstTargets[6].name }}</div>
                            <p v-if="university.firstTargets.length > 6">{{ university.firstTargets[6].content }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>2.2 稳拿院校</h3>
                            <div v-if="university.secondTargets.length > 0">
                                <div class="a4_mian_school_title">{{ university.secondTargets[0].name }}</div>
                                <p>{{ university.secondTargets[0].content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.secondTargets.length > 1 && university.secondTargets[1].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <div class="a4_mian_school_title">{{ university.secondTargets[1].name }}</div>
                            <p>{{ university.secondTargets[1].content }}</p>
                            <div class="a4_mian_school_title" v-if="university.secondTargets.length > 2">{{
                                university.secondTargets[2].name }}</div>
                            <p v-if="university.secondTargets.length > 2">{{ university.secondTargets[2].content }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.secondTargets.length > 3 && university.secondTargets[3].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <div class="a4_mian_school_title">{{ university.secondTargets[3].name }}</div>
                            <p>{{ university.secondTargets[3].content }}</p>
                            <div class="a4_mian_school_title" v-if="university.secondTargets.length > 4">{{
                                university.secondTargets[4].name }}</div>
                            <p v-if="university.secondTargets.length > 4">{{ university.secondTargets[4].content }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.secondTargets.length > 5 && university.secondTargets[5].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <div class="a4_mian_school_title">{{ university.secondTargets[5].name }}</div>
                            <p>{{ university.secondTargets[5].content }}</p>
                            <div class="a4_mian_school_title" v-if="university.secondTargets.length > 6">{{
                                university.secondTargets[6].name }}</div>
                            <p v-if="university.secondTargets.length > 6">{{ university.secondTargets[6].content }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>2.3 保底院校</h3>
                            <div v-if="university.thirdTargets.length > 0">
                                <h4>{{ university.thirdTargets[0].name }}</h4>
                                <p>{{ university.thirdTargets[0].content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.thirdTargets.length > 1 && university.thirdTargets[1].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h4>{{ university.thirdTargets[1].name }}</h4>
                            <p>{{ university.thirdTargets[1].content }}</p>
                            <h4 v-if="university.thirdTargets.length > 2">{{ university.thirdTargets[2].name }}</h4>
                            <p v-if="university.thirdTargets.length > 2">{{ university.thirdTargets[2].content }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.thirdTargets.length > 3 && university.thirdTargets[3].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h4>{{ university.thirdTargets[3].name }}</h4>
                            <p>{{ university.thirdTargets[3].content }}</p>
                            <h4 v-if="university.thirdTargets.length > 4">{{ university.thirdTargets[4].name }}</h4>
                            <p v-if="university.thirdTargets.length > 4">{{ university.thirdTargets[4].content }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px"
                v-if="university.thirdTargets.length > 5 && university.thirdTargets[5].name != ''">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h4>{{ university.thirdTargets[5].name }}</h4>
                            <p>{{ university.thirdTargets[5].content }}</p>
                            <h4 v-if="university.thirdTargets.length > 6">{{ university.thirdTargets[6].name }}</h4>
                            <p v-if="university.thirdTargets.length > 6">{{ university.thirdTargets[6].content }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_title">四、提升建议</div>
                        <div class="a4_mian_content_text">
                            <h3>1. 绩点</h3>
                            <h4>1.1绩点培优建议概括</h4>
                            <p v-if="improvement.gradeContent">{{ improvement.gradeContent.summarize }}</p>
                            <h4>1.2 重点科目及复习计划</h4>
                            <p v-if="improvement.gradeContent">{{ improvement.gradeContent.details }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>2. 英语</h3>
                            <h4>2.1 英语培优建议概括</h4>
                            <p v-if="improvement.EnglishContent">{{ improvement.EnglishContent.summarize }}</p>
                            <h4>2.2.1.1 长期规划（一年及以上）</h4>
                            <p><em
                                    style="font-weight: bold;font-style:normal">词汇：</em>每天固定时间（如早晨或睡前）学习新词汇，并复习前一天学习的词汇。每周至少进行一次词汇测试。
                            </p>
                            <p><em
                                    style="font-weight: bold;font-style:normal">听力：</em>每天至少30分钟听力练习，可以选择BBC、CNN等英语新闻，或者TED演讲、英语电影、电视剧等。
                            </p>
                            <p><em
                                    style="font-weight: bold;font-style:normal">阅读：</em>每周至少阅读一篇英文文章或短文，可以是学术性的论文摘要，也可以是英文小说、杂志等。
                            </p>
                            <p><em style="font-weight: bold;font-style:normal">翻译：</em>每月至少进行一次中英互译练习，可以选择教材、文章或新闻进行翻译。
                            </p>
                            <p><em
                                    style="font-weight: bold;font-style:normal">作文：</em>每月至少写一篇英文作文，可以是议论文、说明文或描述文，注重逻辑和表达。
                            </p>
                            <p><em
                                    style="font-weight: bold;font-style:normal">模拟测试：</em>每季度进行一次完整的英语模拟测试，包括听力、阅读、翻译和写作部分，以检验学习成果。
                            </p>
                            <h4>2.2.1.2 短期计划（一个月至三个月）</h4>
                            <p><em
                                    style="font-weight: bold;font-style:normal">词汇：</em>每天学习并复习50个新词汇，每周进行一次词汇测试，并重点复习错误词汇。
                            </p>
                            <p><em
                                    style="font-weight: bold;font-style:normal">听力：</em>每天至少60分钟听力练习，可以选择难度稍高的材料，如英语学术讲座、专业英语等。
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <p><em style="font-weight: bold;font-style:normal">阅读：</em>每天阅读一篇英文文章，并尝试总结文章大意和作者观点。</p>
                            <p><em style="font-weight: bold;font-style:normal">翻译：</em>每周进行两次中英互译练习，注重翻译的准确性和流畅性。</p>
                            <p><em style="font-weight: bold;font-style:normal">作文：</em>每两周写一篇英文作文，并请老师或英语好的同学进行修改和点评。
                            </p>
                            <p><em style="font-weight: bold;font-style:normal">模拟测试：</em>每月进行一次英语模拟测试，重点分析错误原因，并进行针对性复习。
                            </p>
                            <h4>2.2.2 培优方法</h4>
                            <p><em
                                    style="font-weight: bold;font-style:normal">词汇：</em>使用词汇书或APP进行学习，如《GRE词汇精选》、《托福核心词汇》等。制作词汇卡片，一面写单词，一面写释义和例句，方便随时复习。在日常生活中尝试使用新学的词汇，如写日记、与朋友交流等。
                            </p>
                            <p><em
                                    style="font-weight: bold;font-style:normal">听力：</em>选择适合自己的听力材料，从易到难逐步提高。听力时先尝试抓住关键词和主旨大意，再逐步理解细节。听力练习后进行总结，记录生词和难点，并尝试复述听到的内容。
                            </p>
                            <p><em style="font-weight: bold;font-style:normal">阅读：</em>阅读时先快速浏览文章标题、段落首句和尾句，了解文章大意。
                                仔细阅读文章，注意理解作者的观点、论据和推理过程。
                                积累阅读中的生词和短语，并尝试在写作中运用。</p>
                            <p><em style="font-weight: bold;font-style:normal">翻译：</em>掌握基本的翻译技巧，如直译与意译的结合、增译与减译等。
                                多练习不同类型的翻译，如文学翻译、科技翻译、商务翻译等。
                                参考优秀的翻译作品，学习他人的翻译方法和表达。</p>
                            <p><em style="font-weight: bold;font-style:normal">作文：</em>熟悉常见的作文类型和结构，如议论文的论点、论据和结论等。
                                多读范文，学习他人的写作风格和表达技巧。
                                写作时注重逻辑性和连贯性，使用恰当的连接词和过渡句。</p>
                            <p><em style="font-weight: bold;font-style:normal">模拟测试：</em>选择权威的模拟测试卷进行练习，如考研英语真题、托福模拟题等。
                                严格按照考试时间进行模拟测试，培养考试感觉和节奏感。
                                测试后认真分析错误原因，并进行针对性复习和巩固。</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h4>2.2.3 个性化提升建议</h4>
                            <p v-if="improvement.EnglishContent">{{ improvement.EnglishContent.details }}</p>
                            <h3>3. 科研</h3>
                            <h4>3.1 科研培优建议概括</h4>
                            <p v-if="improvement.scientificResearchContent">{{
                                improvement.scientificResearchContent.summarize }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h4>3.2 科研培优详细建议</h4>
                            <p>提升保研竞争力的两大科研参与策略：</p>
                            <p>一、积极投身教师的科研项目</p>
                            <p>在大学阶段，老师们不仅肩负着教书育人的重任，还致力于科学研究。作为本科生，通过参与老师的科研项目，我们能够获得宝贵的科研经验。首先，我们可以通过学院官网深入了解每位老师的简历，从中发现他们正在开展或已完成的项目，以及他们的主要研究方向。在确定了自己对这些项目感兴趣且有能力胜任后，我们应主动与老师取得联系，表达想要加入项目的意愿。尽管有时可能会担心被拒绝，但勇于尝试总是值得的。老师们通常非常欢迎那些成绩优异且积极主动的学生，因为他们在忙碌时可以提供有力的支持。
                            </p>
                            <p>在联系老师时，建议通过电子邮件这一正式且清晰的方式。在邮件中，我们可以简要介绍自己的学习情况和所具备的能力，同时表达对项目的浓厚兴趣。如果这位老师恰好是我们的任课老师，那么在课后与老师面对面沟通也是一个直接且高效的选择。
                            </p>
                            <p>关于时间规划，建议从大一开始进行专业知识的积累，大二时则尝试申请加入老师的科研项目。因为科研项目从开展到取得成果往往需要至少一年的时间，而大三下学期通常是准备保研夏令营等关键时期。因此，提前准备将大大增加我们的成功率。当然，不同院校的项目申报时间可能会有所不同，具体以当年院校公布的时间为准。
                            </p>
                            <p>二、加入全国大学生创新创业训练计划（大创）</p>
                            <p>“大创”是一个旨在鼓励大学生创新创业的国家级计划，分为国家级、省级和校级三个层次。其中，国家级“大创”又包括创新训练项目、创业训练项目和创业实践项目三种类型。</p>
                            <p>创新训练项目允许本科生个人或团队在导师的指导下，独立完成创新性研究项目的设计、实施、报告撰写及学术交流等工作。而创业训练项目则要求本科生团队在导师的指导下，通过编制商业计划书、开展可行性研究、模拟企业运行等方式，进行创业训练。至于创业实践项目，则是学生团队在学校导师和企业导师的共同指导下，利用前期创新训练项目的成果，提出具有市场前景的创新产品或服务，并开展创业实践活动。
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <p>在时间规划上，同样建议从大一开始进行专业准备，大二时申请加入“大创”项目。从申请到立项，再到研究成果的发表，一般需要一年左右的时间。因此，为了在大三下学期能够顺利准备并参加保研夏令营等活动，我们应尽早开始准备。当然，不同院校的项目时间可能会有所差异，具体以当年院校公布的时间为准。
                                通过积极参与教师的科研项目和“大创”项目，我们不仅能够提升自己的科研能力和创新能力，还能为保研增添更多的竞争力。</p>
                            <p>具体的提升建议如下：</p>
                            <p v-if="improvement.scientificResearchContent">{{
                                improvement.scientificResearchContent.details }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>4. 竞赛</h3>
                            <h4>4.1 竞赛培优建议概括</h4>
                            <p v-if="improvement.contestContent">{{
                                improvement.contestContent.summarize }}</p>
                            <h4>4.2 竞赛培优详细建议</h4>
                            <p v-if="improvement.contestContent">{{
                                improvement.contestContent.details }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>5. 项目</h3>
                            <h4>5.1 项目培优建议概括</h4>
                            <p v-if="improvement.projectContent">{{
                                improvement.projectContent.summarize }}</p>
                            <h4>5.2 项目培优详细建议</h4>
                            <p v-if="improvement.projectContent">{{
                                improvement.projectContent.details }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/mian_bg-1@2x.png" class="mian_bg-1" alt="">
                <img src="../assets/img/mian_bg-2@2x.png" class="mian_bg-2" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
                <div class="a4_mian">
                    <div class="a4_mian_content">
                        <div class="a4_mian_content_text">
                            <h3>6. 社会实践</h3>
                            <h4>6.1 社会实践培优建议概括</h4>
                            <p v-if="improvement.practiceContent">{{
                                improvement.practiceContent.summarize }}</p>
                            <h4>6.2 社会实践培优详细建议</h4>
                            <p v-if="improvement.practiceContent">{{
                                improvement.practiceContent.details }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="papers main-rate" style="page-break-after: always;width: 840px">
                <img src="../assets/img/rectangle 2@2x.png" class="a4_main_rectangle2" alt="">
                <img src="../assets/img/polygon 2@2x.png" class="a4_main_polygon2" alt="">
                <img src="../assets/img/rectangle 1@2x.png" class="a4_main_rectangle" alt="">
                <div class="a4_mian_footer">
                    本报告由夏岁智能夏怡AI生成，最终解释权归北京夏岁智能科技有限公司所有
                </div>
            </div>
        </div>
        <!-- <el-button @click="gohtml">导出</el-button> -->
        <el-button class="export-btn" type="primary" :loading="loading" @click="exportImg">{{ loading ? '导出中 ...' : '导出'
            }}</el-button>
    </div>
</template>

<script>
import htmlDocx from 'html-docx-js/dist/html-docx'
import saveAs from 'file-saver'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { text } from '@fortawesome/fontawesome-svg-core';
export default {
    name: 'index',
    data() {
        return {
            // current: '',
            pages: [],
            userInfoContent: {},
            loading: false,
            title: '', // 报告名称
            university: {},
            improvement: {},
            userName: ''
        }
    },
    methods: {
        // splitIntoPages(content, pageSize) {
        //     const pages = [];
        //     let currentPage = '';
        //     const words = content.split(' ');

        //     words.forEach(word => {
        //         if ((currentPage + word).length < pageSize) {
        //             currentPage += (currentPage ? ' ' : '') + word;
        //         } else {
        //             pages.push(currentPage);
        //             currentPage = word;
        //         }
        //     });

        //     if (currentPage) {
        //         pages.push(currentPage);
        //     }

        //     return pages;
        // },
        // paginateContent() {
        //     const maxPageHeight = 900; // 每个页面的最大高度
        //     const content = this.university.targets;

        //     const pages = [];
        //     let currentPage = [];
        //     let currentHeight = 0;

        //     content.forEach(item => {
        //         const itemHeight = 30 + 25 * (item.text.split('\n').length + 1); // 假设每行高度为 25px，标题高度为 30px
        //         if (currentHeight + itemHeight > maxPageHeight) {
        //             pages.push(currentPage);
        //             currentPage = [item];
        //             currentHeight = itemHeight;
        //         } else {
        //             currentPage.push(item);
        //             currentHeight += itemHeight;
        //         }
        //     });

        //     if (currentPage.length > 0) {
        //         pages.push(currentPage);
        //     }

        //     this.pages = pages;
        // },
        //     gohtml() {
        //         const app = document.getElementById('view')
        //         const cloneApp = app.cloneNode(true)
        //         const canvases = app.getElementsByTagName('canvas')
        //         const cloneCanvases = cloneApp.getElementsByTagName('canvas')
        //         const promises = Array.from(canvases).map((ca, index) => {
        //             return new Promise((res) => {
        //                 const url = ca.toDataURL('image/png', 1)
        //                 const img = new Image()
        //                 img.onload = () => {
        //                     URL.revokeObjectURL(url)
        //                     res()
        //                 }
        //                 img.src = url
        //                 // 生成img插入clone的dom的canvas之前
        //                 cloneCanvases[index].parentNode.insertBefore(img, cloneCanvases[index])
        //             })
        //         })
        //         // 移除原来的canvas
        //         const cloneCanvas = cloneApp.getElementsByTagName('canvas')
        //         Array.from(cloneCanvas).forEach((ca) => ca.parentNode.removeChild(ca))

        //         Promise.all(promises).then(() => {
        //             console.log(cloneApp.outerHTML)
        //             this.convertImagesToBase64(cloneApp)
        //             const converted = htmlDocx.asBlob(`
        //   <html xmlns:o=\'urn:schemas-microsoft-com:office:office\' xmlns:w=\'urn:schemas-microsoft-com:office:word\' xmlns=\'http://www.w3.org/TR/REC-html40\'><head><style>
        //   ${document.head.outerHTML}
        //   </head>
        //   <body>
        //   ${cloneApp.outerHTML}
        //   </body>
        //   </html>`)
        //             saveAs(converted, 'test.docx')
        //         })
        //     },
        //     convertImagesToBase64(cloneApp) {
        //         var regularImages = cloneApp.getElementsByTagName('img')
        //         var canvas = document.createElement('canvas')
        //         var ctx = canvas.getContext('2d')
        //         regularImages.forEach((item) => {
        //             canvas.width = item.width
        //             canvas.height = item.height
        //             ctx.drawImage(item, 0, 0, item.width, item.height)
        //             var ext = item.src.substring(item.src.lastIndexOf('.') + 1).toLowerCase()
        //             var dataURL = canvas.toDataURL('image/' + ext)
        //             item.setAttribute('src', dataURL)
        //         })
        //         canvas.remove()
        //     },

        gohtml() {
            const app = document.getElementById('view');
            console.log(app);

            // 获取 HTML 内容并移除 script 标签
            const htmlContent = app.innerHTML.replace(/(<script[\s\S]*?<\/script>)/gi, '');

            // 修正模板字符串
            const template = `
    <html xmlns:o='urn:schemas-microsoft-com:office:office' 
          xmlns:w='urn:schemas-microsoft-com:office:word' 
          xmlns='http://www.w3.org/TR/REC-html40'>
    <head>
        <meta charset='utf-8'>
        <title>MyDocument</title>
    </head>
    <body>
        ${htmlContent}
    </body>
    </html>`;

            // 生成 Blob 文件
            const blob = new Blob(['\ufeff', template], { type: 'application/msword' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');

            a.href = url;
            a.target = '_blank';
            a.download = 'MyDocument.doc';
            document.body.appendChild(a);
            a.click();

            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        },

        exportImg() {
            this.loading = true;
            // this.$nextTick:模块加载完成在导出，避免截图不全
            this.$nextTick(() => {
                this.$refs.result.scrollTop = 0; // 避免截图不全
                let dom = document.getElementById('view')
                new html2canvas(dom, {
                    useCORS: true,
                    // dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
                    // scale: 4 //按比例增加分辨率
                }).then(canvas => {
                    let contentWidth = canvas.width;
                    let contentHeight = canvas.height;

                    //一页pdf显示html页面生成的canvas高度;
                    let pageHeight = contentWidth / 592.28 * 841.89;
                    //未生成pdf的html页面高度
                    let leftHeight = contentHeight;
                    //页面偏移
                    let position = 0;
                    //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                    let imgWidth = 595.28;
                    let imgHeight = 595.28 / contentWidth * contentHeight;

                    let pageData = canvas.toDataURL('image/jpeg', 1.0);

                    let pdf = new JsPDF('', 'pt', 'a4');

                    //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                    //当内容未超过pdf一页显示的范围，无需分页
                    if (leftHeight < pageHeight) {
                        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
                    } else {
                        while (leftHeight > 0) {
                            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight;
                            position -= 841.89;
                            //避免添加空白页
                            if (leftHeight > 0) {
                                pdf.addPage();
                            }
                        }
                    }
                    // 获取当前时间
                    const now = new Date();

                    // 格式化时间，例如：20250123_143059
                    const formattedTime = now.toISOString()
                        .replace(/[-:.TZ]/g, '') // 移除日期和时间中的特殊字符
                        .slice(0, 15); // 截取到秒（YYYYMMDDTHHMMSS）
                    pdf.save(`${this.userName}同学保研深度定位报告_${formattedTime}.pdf`);
                    this.loading = false
                });
            })
        },
        pages() {
            const pages = [];
            const maxContentHeight = 1000; // 设置最大内容高度
            let currentPage = { content: '' };

            this.university.targets.forEach((item) => {
                const itemContent = `<h4>${item.name}</h4><p>${item.content}</p>`;
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = itemContent;
                const itemHeight = tempDiv.scrollHeight;

                if (currentPage.content.length === 0 || itemHeight + currentPage.scrollHeight <= maxContentHeight) {
                    currentPage.content += itemContent;
                } else {
                    pages.push(currentPage);
                    currentPage = { content: itemContent };
                }
            });

            if (currentPage.content.length > 0) {
                pages.push(currentPage);
            }

            return pages;
        },
    },
    created() {
        // 获取 query 参数
        this.userInfoContent = this.$route.query.current;
        this.university = this.$route.query.university;
        this.improvement = this.$route.query.improvement;
        this.userName = this.$route.query.userName;
        if (this.university) {
            pages()
        }
        console.log('Received current:', this.userInfoContent);
        console.log('Received university:', this.university);
        console.log('Received improvement:', this.improvement);
    },
}
</script>

<style scoped>
.report {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
}

.papers {
    width: 1200px;
    min-height: 1188px;
    /* box-shadow: -1px -1px 26px -3px rgba(0, 0, 0, 0.3); */
    background-color: #F2F1F2;
    /* border-radius: 2px; */
    /* padding: 30px; */
    flex-shrink: 0;
    flex-grow: 0;
    /* margin-bottom: 20px; */
    font-family: STKaiti, STKaiti;
    color: #000;
    /* display: inline-block; */
    position: relative;

    page-break-after: auto;
    /* 自动分页 */
    page-break-inside: avoid;
    /* 避免在元素内部分页 */
    break-after: page;
    /* 在元素之后分页 */
    break-inside: avoid;
    /* 避免在元素内部分页 */
}

.a4_title {
    width: 55%;
    position: absolute;
    top: 11%;
    left: 4.3%;
    z-index: 99;
}

.a4_title_en {
    font-size: 30px;
    color: #7A557B;
    position: absolute;
    top: 23.5%;
    left: 8%;
    z-index: 99;
}

.a4_polygon1 {
    width: 36.8%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.a4_bg {
    width: 100%;
    position: absolute;
    top: 8.1%;
    left: 0;
    z-index: 2;
}

.a4_polygon2 {
    width: 61%;
    position: absolute;
    left: 0;
    top: 48.7%;
}

.a4_rectangle {
    width: 61%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.a4_report {
    width: 50%;
    position: absolute;
    left: 3%;
    bottom: 2%;
}

.a4_content {
    width: 30%;
    position: absolute;
    right: 5%;
    top: 51%;
    text-align: left;
    color: #7A557B;
    font-size: 20px;
    line-height: 30px;
}

.a4_content_title {
    font-size: 30px;
    line-height: 45px;
    font-weight: bold;
    font-family: STXinwei, STXinwei;
}

.a4_footer {
    width: 100%;
    position: absolute;
    bottom: 0.5%;
    font-size: 30px;
    color: #494949;
}

.mian_bg-1 {
    width: 100%;
    position: absolute;
    right: 0;
    /* top: 0; */
}

.mian_bg-2 {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
}

.a4_mian_footer {
    width: 100%;
    position: absolute;
    bottom: 2.5%;
    font-size: 14px;
    color: #333333;
}

.a4_main_rectangle2 {
    width: 65%;
    position: absolute;
    right: 0;
    top: 0;
}

.a4_main_polygon2 {
    width: 60%;
    position: absolute;
    left: 0;
    top: 25%;
}

.a4_main_rectangle {
    width: 60%;
    position: absolute;
    left: 0;
    top: 40%;
}

.a4_mian {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 10%;
}

.a4_mian_content {
    width: 80%;
}

.a4_mian_content_title {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
}

.a4_mian_content_text {
    text-align: left;
    font-size: 18px;
    line-height: 25px;
    max-height: 1000px;
    /* 根据实际页面大小调整 */
    overflow: hidden;
    /* 隐藏超出部分 */
}

.a4_mian_content_text p {
    text-indent: 2em;
    margin: 0;
}

.export-btn {
    position: fixed;
    bottom: 10px;
    right: 10px;
    height: 50px;
    width: 200px;
}

.a4_mian_school_title {
    font-size: 18px;
    margin: 10px 0;
    font-weight: bold;
}
</style>