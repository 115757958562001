<template>
    <div class="university_detail">
        <el-page-header @back="goBack" content="详情页面">
        </el-page-header>
        <div style="padding: 30px 150px 50px 150px;">
            <div class="university_detail_title">{{ university }}</div>
            <div class="university_detail_content">
                <el-row style="margin-bottom: 20px;">
                    <el-col :span="12">
                        <div class="university_detail_content_text">
                            地区：{{ schoolInfo.area }}
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="university_detail_content_text">
                            软科综合排名：{{ schoolInfo.rkRank }}
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px;">
                    <el-col :span="12">
                        <div class="university_detail_content_text">
                            所属：{{ schoolInfo.belong }}
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="university_detail_content_text">
                            QS世界排名：{{ schoolInfo.qsRank }}
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px;">
                    <el-col :span="12">
                        <div class="university_detail_content_text">
                            招生电话：{{ schoolInfo.telephone }}
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="university_detail_content_text">
                            US世界排名：{{ schoolInfo.usRank }}
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px;">
                    <el-col :span="12">
                        <div class="university_detail_content_text">
                            官网地址：{{ schoolInfo.link }}
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="university_detail_content_text">
                            校友会排名：{{ schoolInfo.xyhRank }}
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="university_detail_content">
                <div class="university_detail_intro">简介</div>
                <div class="university_detail_intro_content">{{ schoolInfo.description }}</div>
            </div>
            <div class="university_detail_content">
                <div class="university_detail_faculty">
                    <div v-for="(item, index) in tabs" :key="index" class="university_detail_faculty_title"
                        :class="{ active: activeTab === index }" @click="toggleTab(index)">
                        {{ item }}
                    </div>
                </div>
                <div class="university_detail_faculty_content" v-if="activeTab === 0">
                    <el-row :gutter="20">
                        <el-col class="university_detail_faculty_content_item"
                            v-for="(department, index) in paginatedDepartments" :key="index" :span="8">
                            <div class="grid-content">{{ department }}</div>
                        </el-col>
                    </el-row>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="departmentCurrentPage" layout="jumper, prev, pager, next, total"
                        :total="departmentTotal" class="university_detail_pagination">
                    </el-pagination>
                </div>
                <div class="university_detail_faculty_content" v-if="activeTab === 1">
                    <el-row :gutter="20">
                        <el-col class="university_detail_faculty_content_item" v-for="(major, index) in paginatedMajor"
                            :key="index" :span="8">
                            <div class="grid-content">{{ major.mname }}</div>
                        </el-col>
                    </el-row>
                    <el-pagination @size-change="handleSizeChangeMajor" @current-change="handleCurrentChangeMajor"
                        :current-page="departmentCurrentPage" layout="jumper, prev, pager, next, total"
                        :total="majorTotal" class="university_detail_pagination">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get_college_detail, get_college_major, get_college_department, get_college_major_like } from "@/network/home";

export default {
    name: "UniversityDetail",
    data() {
        return {
            university: null,
            schoolInfo: {},
            activeTab: 0,
            tabs: ["院系", "专业"],
            departmentCurrentPage: 1,
            departmentItemsPerPage: 9,
            departmentTotal: 0,
            departmentList: [],
            majorCurrentPage: 1,
            majorItemsPerPage: 9,
            majorTotal: 0,
            majorList: [],
        }
    },
    computed: {
        paginatedDepartments() {
            const start = (this.departmentCurrentPage - 1) * this.departmentItemsPerPage;
            const end = start + this.departmentItemsPerPage;
            return this.departmentList.slice(start, end);
        },
        paginatedMajor() {
            const start = (this.majorCurrentPage - 1) * this.majorItemsPerPage;
            const end = start + this.majorItemsPerPage;
            return this.majorList.slice(start, end);
        }
    },
    methods: {
        goBack() {
            console.log('go back');
            this.$router.back();
        },
        toggleTab(index) {
            this.activeTab = index;
        },
        getCollegeDetail() {
            get_college_detail({
                email: this.$store.getters.email,
                university: this.university,
            }).then(res => {
                console.log(res);
                this.schoolInfo = res.result;
            })
        },
        getCollegeMajor() {
            get_college_major({
                email: this.$store.getters.email,
                university: this.university,
            }).then(res => {
                console.log(res);
                if (res && Array.isArray(res.results) && res.results.length > 0) {
                    this.majorList = res.results;
                    this.majorTotal = res.results.length;
                } else {
                    console.error("No data or invalid response format");
                }
            })
        },
        getCollegeDepartment() {
            get_college_department({
                email: this.$store.getters.email,
                university: this.university,
            }).then(res => {
                console.log(res);
                if (res && Array.isArray(res.results) && res.results.length > 0) {
                    this.departmentList = res.results;
                    this.departmentTotal = res.results.length;
                } else {
                    console.error("No data or invalid response format");
                }
            })
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.departmentItemsPerPage = val;
            this.departmentCurrentPage = 1;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.departmentCurrentPage = val;
        },
        handleSizeChangeMajor(val) {
            this.majorItemsPerPage = val;
            this.majorCurrentPage = 1;
        },
        handleCurrentChangeMajor(val) {
            this.majorCurrentPage = val;
        },
    },
    created() {
        const universityData = this.$route.query.university;
        this.university = JSON.parse(universityData); // 如果传递的是 JSON 字符串，需要反序列化
        console.log("Received university data:", this.university);
        this.getCollegeDetail()
        this.getCollegeMajor()
        this.getCollegeDepartment()
    },
}
</script>

<style scoped>
.university_detail {
    /* padding: 50px 150px; */
    background: #F3E6F6;
}

.university_detail .el-page-header{
    padding: 20px 80px;
    background: #7A557B;
    color: #FFFFFF;
}

.university_detail :deep(.el-page-header__content){
    color: #FFFFFF;
}

.university_detail_title {
    font-size: 30px;
    color: #3D3D3D;
    margin-bottom: 20px;
}

.university_detail_content {
    padding: 50px;
    background: #FFFFFF;
    border-radius: 3px 3px 3px 3px;
    text-align: left;
    margin-bottom: 30px;
}

.el-col {
    display: flex;
    justify-content: center;
}

.university_detail_content_text {
    width: 60%;
    text-align: left;
}

.university_detail_intro {
    background: #7A557B;
    border-radius: 1px 1px 1px 1px;
    color: #FFFFFF;
    line-height: 16px;
    width: 100px;
    text-align: center;
    padding: 10px;
    margin-bottom: 30px;
}

.university_detail_intro_content {
    margin-left: 30px;
    text-indent: 2em;
}

.university_detail_faculty {
    display: flex;
    width: 150px;
    border-radius: 1px 1px 1px 1px;
    margin-bottom: 30px;
}

.university_detail_faculty_title {
    width: 50%;
    background: #F8F7F7;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.university_detail_faculty_title.active {
    background: #9C7DAE;
}

.university_detail_faculty_content_item {
    margin-bottom: 10px;
}

.grid-content {
    width: 100%;
    background: #F8F7F7;
    padding: 15px 25px;
}

.university_detail_pagination {
    text-align: center;
    margin-top: 20px;
}
</style>